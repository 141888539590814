<template>
	<router-view />
</template>

<script setup></script>
<style lang="less">
* {
	margin: 0;
	padding: 0;
}
</style>
