<template>
	<div>
		<van-back-top class="custom" right="2vw" bottom="10vh" style="background-color: #ffffff00; border-radius: 0; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12)"><img src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/fhtop.png" alt="" /></van-back-top>
	</div>
</template>

<script setup></script>
<style scoped lang="less">
.custom {
	img {
		width: 71px;
		height: 80px;
	}
}
@media screen and (max-width: 769px) {
	.custom {
		img {
			width: 45px;
			height: 50px;
		}
	}
}
</style>
