<template>
  <div class="home">
    <div class="Nav_Type_center">
      <img
        alt=""
        class="logo_img"
        src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/logo.png"
        @click="router.push('/')"
      />
      <div class="NavSize">
        <div class="aa">
          <a v-for="(item, index) of list" :key="index" :class="{ changeStyle: changeSelectStyle === index }" @click="xktz(index)">{{ item.name }}</a>
        </div>

        <div class="ssbtn">
          <input v-model="textt" class="ipt" placeholder="请输入事项" type="text" />
          <a class="ssicon">
            <img style="cursor: pointer" alt="" src="../assets/images/search.png" @click="djtz" />
          </a>
        </div>
        <a href="https://oa.cimos.cn/oa/zmq_login.html" target="_blank" style="color: #ffffff; margin-right: 63px">登录</a>
        <a id="assist-open" style="color: #ffffff">无障碍</a>
      </div>
    </div>
  </div>
</template>
<script setup>
// import wzi from "./wzi"
import "./wzi";
import "../components/zmq.json";
import { defineProps, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter(); //路由
defineProps({
  changeSelectStyle: {
    type: Number,
    required: true,
    default: 0,
  },
});
const list = ref([
  {
    name: "首页",
    path: "/",
  },
  {
    name: "数智成果",
    path: "/achievement",
  },
  {
    name: "新闻资讯",
    path: "/message",
  },
  {
    name: "数智自贸",
    path: "/FreeTrade",
  },
  {
    name: "一网通办",
    path: "/GeneralOffice",
  },
  {
    name: "关于我们",
    path: "/AboutUs",
  },
  {
    name: "云上自贸",
    path: "/FinancialMedium",
  },
]);
const xktz = (index) => {
  if (index == 0) {
    let { href } = router.resolve(`/`);
    window.open(href, "_blank");
  } else if (index == 1) {
    let { href } = router.resolve(`/achievement`);
    window.open(href, "_blank");
  } else if (index == 2) {
    let { href } = router.resolve(`/message`);
    window.open(href, "_blank");
  } else if (index == 3) {
    let { href } = router.resolve(`/FreeTrade`);
    window.open(href, "_blank");
  } else if (index == 4) {
    let { href } = router.resolve(`/GeneralOffice`);
    window.open(href, "_blank");
  } else if (index == 5) {
    let { href } = router.resolve(`/AboutUs`);
    window.open(href, "_blank");
  } else if (index == 6) {
    let { href } = router.resolve(`/FinancialMedium`);
    window.open(href, "_blank");
  }
};
const textt = ref("");
const djtz = () => {
  location.href = "http://117.73.9.220:8866/web/zimaoqu/powerList.do?itemName=" + textt.value;
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.changeStyle {
  border-bottom: 3px solid #2577ff;
}

a {
  text-decoration: none;
}

.home {
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;

  .Nav_Type_center {
    margin-top: 32px;
    display: flex;
    align-items: center;

    .logo_img {
      margin-right: 150px;
      cursor: pointer;
    }

    .NavSize {
      display: flex;

      .aa {
        a {
          cursor: pointer;
          font-size: 15px;
          color: #ffffff;
          margin-right: 63px;
          padding-bottom: 17px;
        }

        a:nth-last-child(1) {
          margin-right: 0px;
        }
      }

      .ssbtn {
        margin-right: 63px;
        width: 150px;
        display: flex;
        justify-content: flex-end;

        .ipt {
          width: 0px;
          font-size: 12px;
          height: 20px;
          transition: 0.3s;
          border: none;
          text-indent: 12px;
          color: #ffffff;
          background-color: #01020600;
        }

        &:hover {
          .ipt {
            width: 100px;
            top: 42px;
            right: 226px;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1480px) {
  .home {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;

    .Nav_Type_center {
      margin-top: 32px;
      display: flex;
      align-items: center;

      .logo_img {
        margin-right: 150px;
        cursor: pointer;
      }

      .NavSize {
        display: flex;

        .aa {
          a {
            cursor: pointer;
            font-size: 15px;
            color: #ffffff;
            margin-right: 45px;
            padding-bottom: 17px;
          }

          a:nth-last-child(1) {
            margin-right: 0px;
          }
        }

        .ssbtn {
          margin-right: 45px;
          width: 150px;
          display: flex;
          justify-content: flex-end;
          .ipt {
            width: 0px;
            font-size: 12px;
            height: 20px;
            transition: 0.3s;
            border: none;
            text-indent: 12px;
            color: #ffffff;
            background-color: #01020600;
          }

          &:hover {
            .ipt {
              width: 100px;
              top: 42px;
              right: 226px;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}
</style>
