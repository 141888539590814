//文件router/index.js
import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import achievement from "../views/achievement.vue";
import message from "../views/message.vue";
import innovate from "../views/innovate.vue";
import details from "../views/details.vue";
import FreeTrade from "../views/FreeTrade.vue";
import GeneralOffice from "../views/GeneralOffice.vue";
import AboutUs from "../views/AboutUs.vue";
import FinancialMedium from "../views/FinancialMedium.vue";
import FreeTradeDetails from "../views/FreeTradeDetails.vue";
import TopGaugeDetails from "../views/TopGaugeDetails.vue";
import szzmxq from "../views/szzmxq.vue";
import szcgej from "../views/szcgej.vue";
import ydszzm from "../views/ydszzm.vue"
/**
 * 创建路由对象
 */
const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/achievement",
		name: "achievement",
		component: achievement,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/innovate",
		name: "innovate",
		component: innovate,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/message",
		name: "message",
		component: message,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/details",
		name: "details",
		component: details,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/FreeTrade",
		name: "FreeTrade",
		component: FreeTrade,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/GeneralOffice",
		name: "GeneralOffice",
		component: GeneralOffice,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/AboutUs",
		name: "AboutUs",
		component: AboutUs,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/FinancialMedium",
		name: "FinancialMedium",
		component: FinancialMedium,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/FreeTradeDetails",
		name: "FreeTradeDetails",
		component: FreeTradeDetails,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/TopGaugeDetails",
		name: "TopGaugeDetails",
		component: TopGaugeDetails,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/szzmxq",
		name: "szzmxq",
		component: szzmxq,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: "/szcgej",
		name: "szcgej",
		component: szcgej,
		meta: {
			title: "中国（山东）自由贸易试验区青岛片区",
		},
	},
	{
		path: '/ydszzm',
		name: 'ydszzm',
		component: ydszzm,
		meta: {
			title: '中国（山东）自由贸易试验区青岛片区'
		}
	}
];

/**
 * 初始化路由 配置
 */
const router = createRouter({
	history: createWebHashHistory(),
	routes,
});
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	next();
});

/**
 * 导出路由
 */
export default router;
