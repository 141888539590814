<template>
	<div class="Computer_terminal">
		<!--    导航栏-->
		<Nav :changeSelectStyle="3" class="nav-home"></Nav>
		<!--    新闻资讯-->
		<div class="achievement_bj"><h1>数智自贸</h1></div>
		<!--    新闻详情-->
		<div class="Part_three">
			<div class="Part_three_bx">
				<div class="bx">
					<h1>
						{{ state.listArray.title }}
						<p @click="router.go(-1)"></p>
					</h1>
				</div>
				<div class="bx_content"><p v-html="state.listArray.content"></p></div>
			</div>
			<div class="bnt" @click="cpxx">
				<span class="bnt1"><span>下载附录</span> <img alt="" src="../assets/xz.png" /></span>
			</div>
		</div>
		<!--		底部栏-->
		<BottomPanel></BottomPanel>
		<BackTop />
	</div>
	<div class="Mobile_terminal">3123123123</div>
</template>

<script setup>
import BackTop from "../components/BackTop.vue";
import Nav from "../components/Nav.vue"; //引入导航栏组件
import BottomPanel from "../components/Mybottom.vue"; //引入底部组件
import axios from "axios"; //引入axios
import api from "../api/api"; //引入cimos接口api
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router"; //引入vue路由
const router = useRouter(); //路由
const { currentRoute } = useRouter();
const route = currentRoute.value;
const state = reactive({
	listArray: [],
});
const cpxx = () => {
	location.href = api.downloadZip + "?type=4" + "&id=" + state.listArray.id;
};
const styleClass = () => {
	axios({
		method: "get",
		url: api.productInfo + "?menuId=" + route.query.id,
	}).then(({ data }) => {
		state.listArray = data.list[route.query.index];
	});
};

onMounted(() => {
	styleClass();
	scrollTo(0, 0);
});
</script>
<style lang="less" scoped>
.nav-home {
	position: absolute;
	background-color: #ffffff00;
}

.achievement_bj {
	height: 410px;
	background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szzm.png");
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	align-items: center;

	h1 {
		margin-left: 18.5%;
		font-size: 42px;
		font-weight: bold;
		color: #ffffff;
		font-family: Microsoft YaHei;
	}
}

.Part_three {
	.Part_three_bx {
		.Part_three_bx_btn {
			display: flex;
			justify-content: center;
			margin-bottom: 90px;

			span {
				padding: 27px 75px 27px 75px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #ffffff;
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #3d7effff;
			}

			span:nth-child(1) {
				margin-right: 57.5px;
			}

			span:nth-child(2) {
				margin-left: 57.5px;
			}

			.changeStyleT {
				background-color: #2577ff;
				color: #ffffff;
			}
		}

		.bx {
			width: 1200px;
			margin: 0 auto;
			padding-bottom: 26px;
			border-bottom: 2px dashed #a7a7a7;
			margin-bottom: 52px;

			h1 {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 36px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #272727;
				margin-bottom: 33px;

				p {
					width: 42px;
					height: 42px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 91px;
					background-image: url(../assets/z.png);

					&:hover {
						background-image: url(../assets/y.png);
						transform: rotate(180deg);
					}
				}
			}

			.xz {
				margin-left: 65px;

				span {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #808080;
					margin-right: 25px;
				}
			}
		}

		.bx_content {
			width: 803px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;

			p {
				width: 803px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #313131;
				line-height: 32px;
				margin-bottom: 42px;
			}

			img {
				margin-bottom: 224px;
			}
		}
	}
}

.bnt {
	display: flex;
	justify-content: space-between;
	justify-content: center;
	margin-bottom: 50px;

	.bnt1 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 240px;
		height: 56px;
		border: 1px solid #b8b8b8;

		span {
			margin-left: 28px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			font-size: 14px;
			color: #4c4c4c;
		}

		img {
			margin-right: 24px;
		}

		&:hover {
			background-color: #2577ff;
		}
	}

	.bnt2 {
		display: flex;
		align-items: center;

		span {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #7e7e7e;
			margin-right: 11px;
		}

		img {
			width: 20px;
			height: 17px;
		}
	}
}

.Mobile_terminal {
	display: none;
}

@media screen and (max-width: 769px) {
	.Mobile_terminal {
		display: block;
	}

	.Computer_terminal {
		display: none;
	}
}
</style>
