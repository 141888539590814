<template>
	<!--导航栏-->
	<ydnav></ydnav>
	<div class="kong"></div>
	<!--  地图·-->
	<div class="bj">
		<div id="map" ref="mapContainer"></div>
	</div>
	<div class="dglist">
		<a v-for="(item, index) of list" :href="item.link" :class="{ mp: mpt == index }">{{ item.ptitle }}</a>
	</div>
	<div class="bottomt">
		<div class="bottom_bxt">
			<div class="bottom_leftt">
				<div class="connection_Tt"><img alt="" src="../assets/images/telephone.png" /><span>0532 86768848</span></div>
				<div class="connection_Tt"><img alt="" src="../assets/images/mailbox.png" /><span>266555</span></div>
				<div class="connection_Tt"><img alt="" src="../assets/images/orientation.png" /><span>山东省青岛市黄岛区鹏湾路57号</span></div>
			</div>
		</div>
		<div>
			<p class="bnt">备案：鲁ICF备13025817号</p>
			<p class="bnt">鲁公网安备：37021002000075</p>
		</div>
	</div>
</template>
<script setup>
import ydnav from "@/components/ydnav.vue";
import axios from "axios"; //引入axios
import api from "../api/api"; //引入cimos接口api
import { ref, reactive, onMounted } from "vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css"; //
import zmq from "../components/zmq.json"; //引入自贸区轮廓图坐标
const list = ref([]); //地图数据
const mapContainer = ref(null); //地图实例
const map = ref(null); //地图
const mpt = ref(null);
const show = ref(false); //位置列表显示隐藏，默认false(隐藏)
const bd = reactive({
	markers: [],
	imgs: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png",
});
const dj = () => {
	show.value = !show.value;
	if (show.value == false) {
		bd.imgs = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png";
	} else {
		bd.imgs = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/sjt.png";
	}
};
const qx = () => {
	show.value = false;
	bd.imgs = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png";
};
const reft = ref(null);
// 默认标点图标
const myIcon = L.icon({
	className: "myIcon",
	iconUrl: require("../assets/dw.png"),
	iconSize: [28, 25],
	iconAnchor: [15, 10],
});
const NewmyIcon = L.icon({
	className: "NewmyIcon",
	iconUrl: require("../assets/images/bd.png"),
	iconSize: [28, 25],
	iconAnchor: [15, 10],
});
// 点击获取地图标点信息
const itenClick = (index, item) => {
	window.open(item.link);
};
const axiosMap = () => {
	// 创建地图实例
	let map = L.map("map", {
		zoom: 12, // 设置缩放级别
		center: [36.04, 120.14], // 地图中心
		zoomControl: false, // + - 按钮
		doubleClickZoom: true, // 双击放大
		attributionControl: false, // 右下角leaflet标识
		zoomAnimation: false,
	});
	// 添加自贸区轮廓图层
	L.geoJSON(zmq, {
		style: {
			stroke: true, // 是否显示轮廓线
			color: "#246FF2", // 轮廓线的颜色
		},
	}).addTo(map);
	// 添加鼠标移入事件 (enable)开启,(disable)关闭
	// let leftResultDiv = document.getElementById("map").onmouseover = () => {
	//   //监听鼠标移入
	//   map.dragging.disable(); //地图拖拽
	//   map.doubleClickZoom.disable(); //禁止双击放大地图
	//   map.scrollWheelZoom.disable(); //禁止鼠标滑轮滚动放大缩小地图
	// };
	// let bj = document.getElementsByClassName("bj").onmouseover = () => {
	//   //监听鼠标移入
	//   map.dragging.disable(); //地图拖拽
	//   map.doubleClickZoom.disable(); //禁止双击放大地图
	//   map.scrollWheelZoom.disable(); //禁止鼠标滑轮滚动放大缩小地图
	// };
	//循环进行多个标点
	for (let i = 0; i < list.value.length; i++) {
		// 创建标点
		bd.markers.push(L.marker([list.value[i].plat, list.value[i].plon]).addTo(map).bindPopup(list.value[i].ptitle).setIcon(myIcon));
	}
	// 添加点击事件监听器
	bd.markers.forEach(function (marker, index) {
		marker.on("click", function () {
			mpt.value = index;
			// 获取当前点击的标记
			var currentMarker = this;
			// // 遍历所有标记，将非当前标记恢复为默认图标
			bd.markers.forEach(function (marker) {
				if (marker !== currentMarker) {
					marker.setIcon(myIcon);
				}
			});
			// 将当前标记设置为其他图标
			marker.setIcon(NewmyIcon);
		});
	});
};
onMounted(() => {
	// 请求地图数据
	axios({ method: "get", url: api.position }).then(({ data }) => {
		list.value = data.list;
		axiosMap();
	});
	scrollTo(0, 0);
});
</script>
//
<style lang="less" scoped>
.kong {
	width: 100%;
	height: 67.51px;
	background-color: rgb(26, 34, 93);
}
.bj {
	#map {
		z-index: 0;
		cursor: pointer;
		width: 100vw;
		height: 67vh;
		opacity: 1;
		background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szrm.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-color: #ffffff00;
	}
}
.bottomt {
	padding: 0 30px 0 55px;
	background-color: #333333;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.bottom_bxt {
		.bottom_leftt {
			margin-top: 20px;

			.connection_Tt {
				margin-bottom: 20px;

				img {
					width: 16px;
					margin-right: 12px;
				}

				span {
					font-size: 13px;
					font-weight: bold;
					color: #ffffff;
				}
			}
		}
	}

	.bnt {
		font-size: 13px;
		font-weight: bold;
		color: #ffffff;
		opacity: 0.6;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 20px;
	}
}
/deep/ .leaflet-popup-content-wrapper,
.leaflet-popup-tip {
	background: #2c326d;
	color: #ffffff;
	box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
	height: 35px;
}

/deep/ .leaflet-popup-tip-container {
	display: none;
}

/deep/ .leaflet-popup-content-wrapper {
	padding: 1px;
	text-align: left;
	border-radius: 0px;
}

/deep/ .leaflet-popup-content {
	margin: 0;
	font-size: 1.08333em;
	min-height: 1px;
	padding: 10px;
	display: flex;
	justify-content: center;
}
/deep/ .leaflet-container a.leaflet-popup-close-button {
	display: none;
}
/deep/ .leaflet-popup {
	opacity: 0.86 !important;
}
.dglist {
	background-color: rgba(37, 119, 255, 0.79);
	padding: 20px 10px 0 10px;
	display: flex;
	flex-wrap: wrap;
	a {
		width: 25%;
		color: white;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		margin-bottom: 5px;
		font-size: 14px;
	}
	.mp {
		font-weight: bolder;
		color: #19256f;
	}
}
</style>
