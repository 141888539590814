<template>
	<div class="Computer_terminal">
		<!--   导航栏 -->
		<Nav :changeSelectStyle="5" class="nav-home"></Nav>
		<!--  关于自贸  -->
		<div class="achievement_bj"><h1>关于自贸</h1></div>
		<!--   简介 -->
		<div class="three">
			<div class="bx">
				<h1>青岛自贸片区简介</h1>
				<div class="content" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
					<span v-for="(item, index) of st.list.otherFiles" :key="index" :class="{ changeStyleT: st.changeSelectStyleT == index }" @click="styleClassT(index)"></span>
				</div>
				<div class="nr">
					<div class="left">
						<h2>{{ st.list.title }}</h2>
						<p class="p" v-html="st.list.content"></p>
					</div>
					<div class="right"><img :src="st.img" alt="" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" /></div>
				</div>
			</div>
		</div>
		<!--	数字委员会-->
		<div class="four">
			<div class="four_bx">
				<div class="four_bx_nr">
					<div class="four_bx_nr_left">
						<h1>数智化委员会</h1>
						<p>为进一步做好中国（山东）自由贸易试验区青岛片区和中德生态园数智化转型有关工作，推动园区数智化建设再提速，赋能数智化管理再创新，决定成立中国（山东）自由贸易试验区青岛片区与青岛中德生态园联合数智化委员会。委员会设立办公室，办公室设在青岛自贸片区经济发展部（大数据部）。</p>
					</div>
					<div class="four_bx_nr_right">
						<div class="dgnr"><img alt="" src="../assets/dh2.png" /> 0532-86768848</div>
						<div class="dgnr"><img alt="" src="../assets/yx2.png" />266555</div>
						<div class="dgnr"><img alt="" src="../assets/dz2.png" />山东省青岛市黄岛区鹏湾路57号</div>
					</div>
				</div>
			</div>
		</div>
		<BottomPanel></BottomPanel>
		<BackTop />
	</div>
	<div class="Mobile_terminal">
		<ydnav></ydnav>
		<!--数智自贸-->
		<div class="achievement_bj">
			<h1>关于自贸</h1>
		</div>
		<div class="threet">
			<div class="bx">
				<h1>青岛自贸片区简介</h1>
				<div class="content" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
					<span v-for="(item, index) of st.list.otherFiles" :key="index" :class="{ changeStyleT: st.changeSelectStyleT == index }" @click="styleClassT(index)"></span>
				</div>
				<div class="nr">
					<div class="left">
						<h2>{{ st.list.title }}</h2>
						<p class="p" v-html="st.list.content"></p>
					</div>
					<div class="right"><img :src="st.img" alt="" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" /></div>
				</div>
			</div>
		</div>
		<div class="bottomt">
			<div class="bottom_bxt">
				<div class="bottom_leftt">
					<div class="connection_Tt"><img alt="" src="../assets/images/telephone.png" /><span>0532 86768848</span></div>
					<div class="connection_Tt"><img alt="" src="../assets/images/mailbox.png" /><span>266555</span></div>
					<div class="connection_Tt"><img alt="" src="../assets/images/orientation.png" /><span>山东省青岛市黄岛区鹏湾路57号</span></div>
				</div>
			</div>
			<div>
				<p class="bnt">备案：鲁ICF备13025817号</p>
				<p class="bnt">鲁公网安备：37021002000075</p>
			</div>
		</div>
	</div>
</template>
<script setup>
import BackTop from "../components/BackTop.vue";
import ydnav from "@/components/ydnav.vue";
import Nav from "../components/Nav.vue"; //引入导航栏组件
import BottomPanel from "../components/Mybottom.vue"; //引入底部组件
import axios from "axios"; //引入axios
import api from "../api/api"; //引入cimos接口api
import { onMounted, reactive } from "vue";

const st = reactive({
	list: [],
	changeSelectStyleT: 0,
	img: "",
	timer: null,
});
const styleClassT = (index) => {
	st.changeSelectStyleT = index;
	st.img = api.Dom + st.list.otherFiles[st.changeSelectStyleT].filePath;
};
const handleMouseEnter = () => {
	stopPlay();
};
const handleMouseLeave = () => {
	Play();
};
const stopPlay = () => {
	clearInterval(st.timer);
	st.timer = null;
};
const Play = () => {
	st.timer = setInterval(() => {
		if (st.changeSelectStyleT < st.list.otherFiles.length - 1) {
			st.changeSelectStyleT++;
		} else {
			st.changeSelectStyleT = 0;
		}
		st.img = api.Dom + st.list.otherFiles[st.changeSelectStyleT].filePath;
	}, 3000);
};
onMounted(() => {
	axios({
		method: "get",
		url: api.other + "?type=1",
	}).then(({ data }) => {
		st.list = data.list[0];
		st.list.otherFiles = data.list[0].otherFiles;
		st.img = api.Dom + st.list.otherFiles[st.changeSelectStyleT].filePath;
	});
	Play();
	scrollTo(0, 0);
});
</script>
<style lang="less" scoped>
.nav-home {
	position: absolute;
	background-color: #ffffff00;
}

.achievement_bj {
	height: 410px;
	background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/gyzm.png");
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	align-items: center;
	margin-bottom: 132px;

	h1 {
		margin-left: 18.5%;
		font-size: 42px;
		font-weight: bold;
		color: #ffffff;
		font-family: Microsoft YaHei;
	}
}

.three {
	.bx {
		width: 1205px;
		margin: 0 auto;

		h1 {
			font-size: 42px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #2577ff;
			margin-bottom: 88px;
		}

		.content {
			display: flex;
			justify-content: flex-end;

			span {
				cursor: pointer;
				width: 54px;
				height: 8px;
				background-color: #e3e3e3;
				margin-left: 9px;
				margin-bottom: 40px;
			}

			.changeStyleT {
				background-color: #2577ff;
			}
		}

		.nr {
			display: flex;
			margin-bottom: 123px;
			display: flex;
			justify-content: space-between;

			.left {
				width: 399px;
				overflow: hidden;

				img {
					width: 20px;
				}

				h2 {
					font-size: 30px;
					font-weight: bold;
					color: #272727;
					margin-bottom: 82px;
				}

				.p {
					font-size: 16px;
					font-weight: 400;
					color: #272727;
					line-height: 32px;
					text-indent: 32px;

					/deep/ img {
						width: 399px;
					}
				}
			}

			.right {
				margin-left: 125px;

				img {
					width: 679px;
					height: 443px;
				}
			}
		}
	}
}

.four {
	height: 630px;
	background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szhwyh.png");

	.four_bx {
		width: 1202px;
		margin: 0 auto;
		padding-top: 188px;

		.four_bx_nr {
			display: flex;

			.four_bx_nr_left {
				h1 {
					font-size: 42px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #f7f7f7;
					margin-bottom: 64px;
				}

				p {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					line-height: 32px;
					text-indent: 32px;
				}
			}

			.four_bx_nr_right {
				margin-left: 250px;

				.dgnr {
					width: 380px;
					height: 70px;
					// padding-left: 27px;
					display: flex;
					align-items: center;
					background-color: #ffffff;

					img {
						margin-left: 27px;
						margin-right: 33px;
					}

					margin-bottom: 27px;
					font-size: 23px;
					font-style: italic;
					font-family: Avenir LT Std;
					font-weight: bolder;
					color: #272727;
				}

				.dgnr:nth-last-child(1) {
					font-size: 19px;
					margin-bottom: 0px;
					font-style: normal;
				}
			}
		}
	}
}

.Mobile_terminal {
	display: none;
}

@media (max-width: 1680px) and(min-width: 1440px) {
	.nav-home,
	/deep/ .bottom_left,
	/deep/ .right_sce,
	.bx,
	.four_bx {
		transform: scale(0.8);
	}
	.achievement_bj {
		height: 410px;
		background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/gyzm.png");
		background-repeat: no-repeat;
		background-position: center center;
		display: flex;
		align-items: center;
		margin-bottom: 0;

		h1 {
			margin-left: 18.5%;
			font-size: 42px;
			font-weight: bold;
			color: #ffffff;
		}
	}
}

@media (max-width: 1439px) and(min-width: 1100px) {
	.nav-home,
	/deep/ .bottom_left,
	/deep/ .right_sce,
	.bx,
	.four_bx {
		transform: scale(0.8);
	}
	.achievement_bj {
		height: 410px;
		background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/gyzm.png");
		background-repeat: no-repeat;
		background-position: center center;
		display: flex;
		align-items: center;
		margin-bottom: 0;

		h1 {
			margin-left: 18.5%;
			font-size: 42px;
			font-weight: bold;
			color: #ffffff;
		}
	}
}

@media screen and (max-width: 769px) {
	.Mobile_terminal {
		display: block;

		.achievement_bj {
			height: 200px;
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/gyzm.png");
			background-repeat: no-repeat;
			background-position: center center;
			display: flex;
			align-items: center;
			margin-bottom: 0px;

			h1 {
				margin-left: 18.5%;
				font-size: 20px;
				font-weight: bold;
				color: #ffffff;
			}
		}

		.threet {
			padding: 30px 30px 0px 30px;

			.bx {
				width: 100%;
				margin: 0 auto;

				h1 {
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #2577ff;
					margin-bottom: 60px;
				}

				.content {
					span {
						cursor: pointer;
						width: 54px;
						height: 8px;
						background-color: #e3e3e3;
						margin-left: 9px;
						margin-bottom: 40px;
					}

					.changeStyleT {
						background-color: #2577ff;
					}
				}

				.nr {
					//display: flex;
					margin-bottom: 123px;
					//display: flex;
					justify-content: space-between;

					.left {
						width: 100%;
						overflow: hidden;

						img {
							width: 20px;
						}

						h2 {
							font-size: 18px;
							font-weight: bold;
							color: #272727;
							margin-bottom: 40px;
						}

						.p {
							font-size: 14px;
							font-weight: 400;
							color: #272727;
							line-height: 32px;
							text-indent: 32px;

							/deep/ img {
								width: 100%;
							}
						}
					}

					.right {
						margin-left: 0px;

						img {
							width: 100%;
							height: 156px;
						}
					}
				}
			}
		}

		.bottomt {
			padding: 0 30px 0 55px;
			background-color: #333333;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.bottom_bxt {
				.bottom_leftt {
					margin-top: 20px;

					.connection_Tt {
						margin-bottom: 20px;

						img {
							width: 16px;
							margin-right: 12px;
						}

						span {
							font-size: 13px;
							font-weight: bold;
							color: #ffffff;
						}
					}
				}
			}

			.bnt {
				font-size: 13px;
				font-weight: bold;
				color: #ffffff;
				opacity: 0.6;
				display: flex;
				justify-content: flex-start;
				margin-bottom: 20px;
			}
		}
	}

	.Computer_terminal {
		display: none;
	}
}
</style>
