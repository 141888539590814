<template>
  <div class="Computer_terminal">
    <!--导航栏-->
    <Nav :changeSelectStyle="1" class="nav_com"></Nav>
    <!-- 数智成果 -->
    <div class="achievement_bj"><h1>数智成果</h1></div>
    <!--成果视频-->
    <div class="achievement_video">
      <div class="video">
        <nut-video :options="state.options" :source="state.source"></nut-video>
      </div>
    </div>
    <!--数智化制度创新-->
    <div class="innovate">
      <div class="innovate_bj">
        <img :src="state.cxzdfm.filePath" />
      </div>
      <div class="innovate_content">
        <div>
          <!-- @click="details(item.id)" -->
          <div v-for="(item, index) of state.innovate" :key="index" class="dg">
            <p class="newstitle">{{ item.newsTitle }}</p>
            <span>▶</span>
          </div>
        </div>
        <div v-if="state.btnnumber > 6" class="btn">
          <div class="spt">
            <span class="left_btn" @click="left_btn()"></span>
            <span class="right_btn" @click="right_btn()"></span>
          </div>
        </div>
      </div>
    </div>
    <!--数智自贸顶规-->
    <div class="Top_gauge">
      <div class="Top_gauge_content">
        <div :class="state.className" @mouseleave="ycpdfl">
          <div class="lbx">
            <div v-for="(item, index) of state.dgPdf" :key="index" class="dgpdf" @click="pdfxq(index, item.id)">
              {{ item.title }}
            </div>
          </div>
          <div v-if="state.btnnumbertt > 8" class="btn">
            <span class="left_btn" @click="left_btnt()"></span>
            <span class="right_btn" @click="right_btnt()"></span>
          </div>
        </div>
        <p lang="zh">{{ state.Top_gauge.remark }}</p>
        <!-- <div class="dgbtn"> -->
        <!-- <div class="yc">
						<span class="dgspan" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">查看顶规<img :src="state.images" alt="" /> </span>
					</div> -->
        <!-- <span class="xzfl" @click="Top_gauge_downloadZip" @mouseenter="handleMouseEnterTT" @mouseleave="handleMouseLeaveTT">下载附录<img :src="state.imgs" alt="" /></span> -->
        <!-- </div> -->
      </div>
      <div class="Top_gauge_bj">
        <img :src="api.Dom + state.Top_gauge.filePath" />
      </div>
    </div>
    <!--导则-->
    <div class="guideline">
      <div class="guideline_bj">
        <img :src="api.Dom + state.guideline.filePath" />
      </div>
      <div class="guideline_content">
        <div :class="state.classNamet" @mouseleave="yc">
          <div class="lbx">
            <div v-for="(item, index) of state.dzpdf" :key="index" class="dgpdf" @click="dzfxq(index, item.id)">
              {{ item.title }}
            </div>
          </div>
          <div v-if="state.btnnumberttt > 8" class="btn">
            <span class="left_btn" @click="left_btntt()"></span>
            <span class="right_btn" @click="right_btntt()"></span>
          </div>
        </div>
        <p lang="zh">{{ state.guideline.remark }}</p>
        <!-- <div class="dzbtn">
					<span class="dgspan" @mouseenter="handleMouseEnterT" @mouseleave="handleMouseLeaveT">查看导则<img :src="state.imagesT" alt="" /></span>
					<span class="xzfl" @click="guideline_downloadZip" @mouseenter="handleMouseEnterTTT" @mouseleave="handleMouseLeaveTTT">下载附录<img :src="state.imgsT" alt="" /></span>
				</div> -->
      </div>
    </div>
    <!--bottom栏 -->
    <Mybottom></Mybottom>
    <BackTop />
  </div>
  <div class="Mobile_terminal">
    <!--导航-->
    <ydnav></ydnav>
    <!--数智成果-->
    <div class="achievement_bj">
      <h1>数智成果</h1>
    </div>
    <!--成果视频-->
    <div class="sp">
      <div class="achievement_video">
        <div class="video">
          <nut-video :options="state.options" :source="state.source"></nut-video>
        </div>
      </div>
    </div>
    <div class="syxw">
      <div class="xwtit"><h1>数智化创新制度</h1></div>
      <div class="innovate_content">
        <div v-for="(item, index) of state.innovatet" :key="index" class="dg" @click="details(item.id)">
          <p class="newstitle">{{ item.newsTitle }}</p>
          <span>▶</span>
        </div>
      </div>
    </div>
    <div class="syxw">
      <div class="xwtit">
        <h1>数智自贸顶规</h1>
        <!-- <span @click="Top_gauge_downloadZip">
					全部下载
					<img src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png" />
				</span> -->
      </div>
      <div class="innovate_content">
        <div v-for="(item, index) of state.dgPdft" :key="index" class="dg">
          <p class="newstitle">{{ item.title }}</p>
          <span class="spanT" @click="yddxzpdf(index, item)">
            查看
            <!-- <img src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png"/> -->
          </span>
        </div>
      </div>
    </div>
    <div class="syxw">
      <div class="xwtit">
        <h1>数智化项目建设运营导则</h1>
        <span @click="guideline_downloadZip">
          全部下载
          <img src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png" />
        </span>
      </div>
      <div class="innovate_content">
        <div v-for="(item, index) of state.dzpdft" :key="index" class="dg">
          <p @click="yddck(index, item)" class="newstitle">{{ item.title }}</p>
          <span @click="yddxzdzpdf(index, item)">
            <img src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png" />
          </span>
        </div>
      </div>
    </div>
    <div class="bottomt">
      <div class="bottom_bxt">
        <div class="bottom_leftt">
          <div class="connection_Tt"><img alt="" src="../assets/images/telephone.png" /><span>0532 86768848</span></div>
          <div class="connection_Tt"><img alt="" src="../assets/images/mailbox.png" /><span>266555</span></div>
          <div class="connection_Tt"><img alt="" src="../assets/images/orientation.png" /><span>山东省青岛市黄岛区鹏湾路57号</span></div>
        </div>
      </div>
      <div>
        <p class="bnt">备案：鲁ICF备13025817号</p>
        <p class="bnt">鲁公网安备：37021002000075</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import BackTop from "../components/BackTop.vue";
import ydnav from "@/components/ydnav.vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import api from "../api/api";
import Nav from "@/components/Nav.vue";
import Mybottom from "@/components/Mybottom.vue";
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router"; //引入vue路由
const router = useRouter(); //路由
const state = reactive({
  source: {
    src: "",
    type: "video/mp4",
  },
  options: {
    controls: true,
    autoplay: true,
    muted: true,
    disabled: true,
    playsinline: true,
    loop: true,
  },
  pageIndex: 1,
  pageSize: 6,
  innovate: [],
  Top_gauge: [],
  guideline: [],
  cxzdfm: [],
  btnnumber: null,
  images: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/right.png",
  imagesT: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/right.png",
  imgs: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png",
  imgsT: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png",
  className: "dglist",
  dgid: null,
  dgPdf: [],
  dzpdf: [],
  classNamet: "dzpdf",
  btnnumbertt: null,
  pageIndext: 1,
  pageSizet: 8,
  btnnumberttt: null,
  pageIndextt: 1,
  pageSizett: 8,
  innovatet: [],
  dgPdft: [],
  dzpdft: [],
});
//查看顶规鼠标移入
const handleMouseEnter = () => {
  state.images = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png";
  state.className = "dglistt";
  state.pageIndext = 1;
  axios({
    method: "get",
    url: api.dgdzlist + "?pid=" + state.dgid,
  }).then(({ data }) => {
    state.dgPdf = data.list;
    state.btnnumbertt = data.total;
  });
};
const yddxzpdf = (index, item) => {
  window.open(`${api.Dom + item.filePath}`);
};
const yddxzdzpdf = (index, item) => {
  window.open(api.xz + "download?filePath=" + item.filePath + "&fileName=" + encodeURIComponent(item.fileName));
};
const yddck = (index, item) => {
  window.open(`${api.Dom + item.filePath}`);
};
//查看顶规鼠标移出
const handleMouseLeave = () => {
  // state.className="dglist"
  state.images = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/right.png";
};
const ycpdfl = () => {
  state.className = "dglist";
};
const pdfxq = (index, id, item) => {
  let a = null;
  if (state.pageIndext == 2) {
    if (index == 0) {
      a = 7 + 1;
    } else {
      a = 8 + index;
    }
  } else {
    a = index;
  }
  // router.push({path: "/TopGaugeDetails", query: {id: id, dg: state.dgid, index: a}});
  // let { href } = router.resolve(`/szcgej?id=${id}&dg=${state.dgid}&index=${a}&type=2`);
  router.push({ path: "/szcgej", query: { id: id, dg: state.dgid, index: a, type: 1 } });
  // window.open(href, "_blank");
};
const dzfxq = (index, id) => {
  let a = null;
  if (state.pageIndextt == 2) {
    if (index == 0) {
      a = 7 + 1;
    } else {
      a = 8 + index;
    }
  } else {
    a = index;
  }
  // router.push({ path: "/szcgej", query: { id: id, dg: state.dgid, index: a, type: 2 } });
  router.push({ path: "/szcgej", query: { id: id, dg: state.guideline.id, index: a, type: 2 } });
};
//查看导则鼠标移入
const handleMouseEnterT = () => {
  state.imagesT = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png";
  state.classNamet = "dzpdft";
  state.pageIndextt = 1;
  axios({
    method: "get",
    url: api.dgdzlist + "?pid=" + state.guideline.id,
  }).then(({ data }) => {
    state.dzpdf = data.list;
    state.btnnumberttt = data.total;
  });
};
const yc = () => {
  state.classNamet = "dzpdf";
};
//查看导则鼠标移出
const handleMouseLeaveT = () => {
  state.imagesT = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/right.png";
};
const handleMouseEnterTT = () => {
  state.imgs = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xzT.png";
};
const handleMouseLeaveTT = () => {
  state.imgs = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png";
};
const handleMouseEnterTTT = () => {
  state.imgsT = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xzT.png";
};
const handleMouseLeaveTTT = () => {
  state.imgsT = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png";
};
const Top_gauge_downloadZip = () => {
  location.href = api.downloadZip + "?type=3" + "&id=" + state.Top_gauge.id;
};
const guideline_downloadZip = () => {
  location.href = api.downloadZip + "?type=3" + "&id=" + state.guideline.id;
};
// const details = (id) => {
// 	let { href } = router.resolve(`/innovate?id=${id}`);
// 	window.open(href, "_blank");
// 	// router.push({ path: "/innovate", query: { id: id } });
// };
const right_btn = async () => {
  if (state.pageIndex == Math.ceil(state.btnnumber / 6)) {
    ElMessage({
      message: "已经是末页",
      type: "warning",
    });
  } else {
    state.pageIndex++;
    await axios({
      method: "get",
      url: api.system,
      params: {
        pageIndex: state.pageIndex,
        pageSize: state.pageSize,
      },
    }).then(({ data }) => {
      state.innovate = data.list;
    });
  }
};
const left_btn = async () => {
  if (state.pageIndex == 1) {
    ElMessage({
      message: "已经是首页",
      type: "warning",
    });
  } else {
    state.pageIndex--;
    await axios({
      method: "get",
      url: api.system,
      params: {
        pageIndex: state.pageIndex,
        pageSize: state.pageSize,
      },
    }).then(({ data }) => {
      state.innovate = data.list;
    });
  }
};
const right_btnt = async () => {
  if (state.pageIndext == Math.ceil(state.btnnumbertt / 8)) {
    ElMessage({
      message: "已经是末页",
      type: "warning",
    });
  } else {
    state.pageIndext++;
    await axios({
      method: "get",
      url: api.dgdzlist + "?pid=" + state.dgid,
      params: {
        pageIndex: state.pageIndext,
        pageSize: state.pageSizet,
      },
    }).then(({ data }) => {
      state.dgPdf = data.list;
    });
  }
};
const left_btnt = async () => {
  if (state.pageIndext == 1) {
    ElMessage({
      message: "已经是首页",
      type: "warning",
    });
  } else {
    state.pageIndext--;
    await axios({
      method: "get",
      url: api.dgdzlist + "?pid=" + state.dgid,
      params: {
        pageIndex: state.pageIndext,
        pageSize: state.pageSizet,
      },
    }).then(({ data }) => {
      state.dgPdf = data.list;
    });
  }
};
const right_btntt = async () => {
  if (state.pageIndextt == Math.ceil(state.btnnumberttt / 8)) {
    ElMessage({
      message: "已经是末页",
      type: "warning",
    });
  } else {
    state.pageIndextt++;
    await axios({
      method: "get",
      url: api.dgdzlist + "?pid=" + state.guideline.id,
      params: {
        pageIndex: state.pageIndextt,
        pageSize: state.pageSizett,
      },
    }).then(({ data }) => {
      state.dzpdf = data.list;
    });
  }
};
const left_btntt = async () => {
  if (state.pageIndextt == 1) {
    ElMessage({
      message: "已经是首页",
      type: "warning",
    });
  } else {
    state.pageIndextt--;
    await axios({
      method: "get",
      url: api.dgdzlist + "?pid=" + state.guideline.id,
      params: {
        pageIndex: state.pageIndextt,
        pageSize: state.pageSizett,
      },
    }).then(({ data }) => {
      state.dzpdf = data.list;
    });
  }
};
const cxck = () => {
  let { href } = router.resolve(`/szcgej?type=0`);
  window.open(href, "_blank");
};
const innovate_list = async () => {
  await axios({
    method: "get",
    url: api.other + "?type=2",
  }).then(({ data }) => {
    if (data.list[0] != []) {
      state.source.src = api.Dom + data.list[0].filePath;
    } else {
      state.source.src = null;
    }
  });
  await axios({
    method: "get",
    url: api.system,
    params: {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
    },
  }).then(({ data }) => {
    state.innovate = data.list;
    state.btnnumber = data.total;
  });
  await axios({
    method: "get",
    url: api.other + "?type=3",
  }).then(({ data }) => {
    state.cxzdfm = data.list[0];
    state.cxzdfm.filePath = api.Dom + state.cxzdfm.filePath;
  });
  await axios({
    method: "get",
    url: api.other + "?type=4",
  }).then(({ data }) => {
    state.Top_gauge = data.list[0];
    state.dgid = data.list[0].id;
  });
  await axios({
    method: "get",
    url: api.other + "?type=5",
  }).then(({ data }) => {
    state.guideline = data.list[0];
  });
  await axios({
    method: "get",
    url: api.system,
  }).then(({ data }) => {
    state.innovatet = data.list;
  });
  await axios({
    method: "get",
    url: api.dgdzlist + "?pid=" + state.dgid,
  }).then(({ data }) => {
    state.dgPdft = data.list;
  });
  await axios({
    method: "get",
    url: api.dgdzlist + "?pid=" + state.guideline.id,
  }).then(({ data }) => {
    state.dzpdft = data.list;
  });
};
onMounted(() => {
  innovate_list();
  scrollTo(0, 0);
});
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.Computer_terminal {
  .nav_com {
    position: absolute;
  }

  .achievement_bj {
    height: 410px;
    background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/achievement.png");
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;

    h1 {
      margin-left: 18.5%;
      font-size: 42px;
      font-weight: bold;
      color: #ffffff;
      font-family: Microsoft YaHei;
    }
  }

  .achievement_video {
    padding-top: 89px;
    padding-bottom: 90px;
    display: flex;
    justify-content: center;

    .video {
      width: 1201px;
      height: 585px;
      z-index: 0;
    }
  }

  .innovate {
    height: 600px;
    background-color: #f1f1f1;
    display: flex;

    .innovate_bj {
      height: 600px;

      margin-right: 120px;

      img {
        width: 52.61vw;
        height: 600px;
      }
    }

    .innovate_content {
      padding-top: 78px;
      display: flex;
      .dg {
        cursor: pointer;
        width: 424px;
        height: 54px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 43px;
        margin-top: 17px;
        font-size: 16px;
        font-weight: 400;
        color: #313131;

        .newstitle {
          width: 300px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        &:hover {
          color: #2577ff;
        }

        span {
          margin-right: 37px;
        }
      }
      .btn {
        display: flex;
        // justify-content: flex-start;
        align-items: center;
        // justify-content: space-between;

        .left_btn {
          width: 41px;
          height: 38px;
          display: flex;
          background-image: url("../assets/z.png");
          margin-left: 40px;
          margin-bottom: 20px;
          margin-top: 230px;
          cursor: pointer;
          &:hover {
            background-image: url("../assets/y.png");
            transform: rotate(180deg);
          }
        }
        .right_btn {
          width: 41px;
          height: 38px;
          display: flex;
          background-image: url("../assets/z.png");
          transform: rotate(180deg);
          margin-left: 40px;
          cursor: pointer;
          &:hover {
            background-image: url("../assets/y.png");
            transform: rotate(0deg);
          }
        }
        .spto {
          cursor: pointer;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #0a0a0a;
          &:hover {
            color: #2577ff;
          }
        }
      }
    }
  }

  .Top_gauge {
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .Top_gauge_content {
      display: flex;
      align-items: center;
    }
    .Top_gauge_content .dgbtn .dgspan:hover .Top_gauge_content .dglist {
      background: red;
    }

    .Top_gauge_content {
      .dglist {
        width: 335px;
        height: 600px;
        background: rgba(49, 51, 51, 0.47);
        position: absolute;
        left: 601px;
        display: none;
      }

      .dglistt {
        width: 335px;
        height: 600px;
        background: #313333ff;
        opacity: 0.95;
        position: absolute;
        left: 601px;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;

        .lbx {
          width: 202px;
          height: 410px;
          overflow: hidden;

          .dgpdf {
            cursor: pointer;
            font-size: 14px;
            width: 202px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            margin-bottom: 33px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;

            &:hover {
              color: #2577ffff;
              font-size: 16px;
            }
          }

          .dgpdf:nth-last-child(1) {
            margin-bottom: 0px;
          }
        }

        .btn {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          flex-direction: row;
          margin-right: 100px;

          .left_btn {
            cursor: pointer;
            width: 41px;
            height: 38px;
            display: flex;
            background-image: url("../assets/z.png");
            margin-right: 10px;

            &:hover {
              background-image: url("../assets/y.png");
              transform: rotate(180deg);
            }
          }

          .right_btn {
            cursor: pointer;
            margin-left: 10px;
            width: 41px;
            height: 38px;
            display: flex;
            background-image: url("../assets/z.png");
            transform: rotate(180deg);

            &:hover {
              background-image: url("../assets/y.png");
              transform: rotate(0deg);
            }
          }
        }
      }

      p {
        width: 657px;
        height: 254px;
        font-size: 14px;
        font-weight: 400;
        color: #434343;
        line-height: 30px;
        // padding-top: 108px;
        margin-right: 85px;
        // margin-bottom: 104px;
        text-indent: 28px;
        text-align: justify;
        // white-space: pre-line;
      }

      .dgbtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 95px;

        .dgspan {
          cursor: pointer;
          width: 212px;
          height: 56px;
          font-size: 14px;
          font-weight: bold;
          color: #434343;
          margin-right: 85px;
          padding: 0px 0px 0px 28px;
          border: 1px solid #919191;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background-color: #2577ff;
            color: #ffffff;
            border: 1px solid #2577ff;
          }

          img {
            width: 20px;
            height: 17px;
            margin-right: 24px;
          }
        }
      }

      .xzfl {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 58px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #7e7e7e;

        &:hover {
          color: #2577ff;
        }

        img {
          margin-left: 11px;
        }
      }
    }

    .Top_gauge_bj {
      height: 600px;

      img {
        height: 600px;
        width: 42.761vw;
      }
    }
  }

  .guideline {
    height: 550px;
    background-color: #f1f1f1;
    display: flex;

    .guideline_bj {
      height: 550px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      img {
        height: 550px;
        width: 42.917vw;
      }
    }

    .guideline_content {
      display: flex;
      align-items: center;
      .dzpdf {
        width: 335px;
        height: 543px;
        background: rgba(49, 51, 51, 0.47);
        position: absolute;
        right: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;

        .lbx {
          width: 202px;
          height: 410px;
          overflow: hidden;

          .dgpdf {
            cursor: pointer;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            margin-bottom: 33px;

            &:hover {
              color: #2577ffff;
              font-size: 16px;
            }
          }

          .dgpdf:nth-last-child(1) {
            margin-bottom: 0px;
          }
        }
      }

      .dzpdft {
        width: 335px;
        height: 550px;
        background: #313333ff;
        opacity: 0.95;
        position: absolute;
        right: 380px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;

        .lbx {
          width: 202px;
          height: 410px;
          overflow: hidden;

          .dgpdf {
            cursor: pointer;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            margin-bottom: 33px;
            width: 202px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            margin-bottom: 33px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;

            &:hover {
              color: #2577ffff;
              font-size: 16px;
            }
          }

          .dgpdf:nth-last-child(1) {
            margin-bottom: 0px;
          }
        }

        .btn {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          flex-direction: row;
          margin-right: 100px;

          .left_btn {
            cursor: pointer;
            width: 41px;
            height: 38px;
            display: flex;
            background-image: url("../assets/z.png");
            margin-right: 10px;

            &:hover {
              background-image: url("../assets/y.png");
              transform: rotate(180deg);
            }
          }

          .right_btn {
            cursor: pointer;
            margin-left: 10px;
            width: 41px;
            height: 38px;
            display: flex;
            background-image: url("../assets/z.png");
            transform: rotate(180deg);

            &:hover {
              background-image: url("../assets/y.png");
              transform: rotate(0deg);
            }
          }
        }
      }

      p {
        width: 635px;
        height: 285px;
        font-size: 14px;
        font-weight: 400;
        color: #434343;
        line-height: 30px;
        // padding-top: 79px;
        margin-left: 101px;
        // margin-bottom: 58px;
        text-align: justify;
        text-indent: 28px;
        // white-space: pre-line;
      }

      .dzbtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-right: 95px;

        .dgspan {
          cursor: pointer;
          width: 212px;
          height: 56px;
          font-size: 14px;
          font-weight: bold;
          color: #434343;
          margin-left: 101px;
          padding: 0px 0px 0px 28px;
          border: 1px solid #919191;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background-color: #2577ff;
            color: #ffffff;
            border: 1px solid #2577ff;
          }

          img {
            width: 20px;
            height: 17px;
            margin-right: 24px;
          }
        }

        .xzfl {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 58px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #7e7e7e;

          &:hover {
            color: #2577ff;
          }

          img {
            margin-left: 11px;
          }
        }
      }
    }
  }
}
.Mobile_terminal {
  display: none;
}

@media (max-width: 1680px) and(min-width: 1440px) {
  .nav_com,
  /deep/ .bottom_left,
  /deep/ .right_sce .innovate .Top_gauge .guideline {
    transform: scale(0.8);
  }

  .Top_gauge .Top_gauge_content .dglistt {
    width: 335px;
    height: 600px;
    background: #313333ff;
    opacity: 0.95;
    position: absolute;
    left: 380px !important;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .guideline .guideline_content .dzpdft {
    width: 335px;
    height: 550px;
    background: #313333ff;
    opacity: 0.95;
    position: absolute;
    right: 165px !important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 1440px) {
  .Top_gauge .Top_gauge_content .dglistt {
    width: 335px;
    height: 600px;
    background: #313333ff;
    opacity: 0.95;
    position: absolute;
    left: 350px !important;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .guideline .guideline_content .dzpdft {
    width: 335px;
    height: 550px;
    background: #313333ff;
    opacity: 0.95;
    position: absolute;
    right: 120px !important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .innovate .innovate_bj {
    height: 600px;
    margin-right: 100px;
  }
  .Computer_terminal .innovate .btn {
    display: flex;
    // flex-direction: column;
    justify-content: flex-end;
    flex-direction: row;
  }
}

@media (max-width: 1439px) and(min-width: 1100px) {
  .nav_com,
  /deep/ .bottom_left,
  /deep/ .right_sce .innovate .Top_gauge .guideline {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 769px) {
  .Mobile_terminal {
    display: block;

    .achievement_bj {
      height: 200px;
      background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/achievement.png");
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      align-items: center;

      h1 {
        margin-left: 18.5%;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .sp {
      padding-left: 30px;
      padding-right: 30px;
      height: 100%;

      .achievement_video {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;

        .video {
          width: 100%;
          height: 100%;
        }
      }
    }

    .syxw {
      padding: 40px 30px 0 30px;
      margin-bottom: 30px;

      .xwtit {
        width: 100%;
        border-bottom: 2px solid #a1a1a1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        margin-bottom: 15px;
        h1 {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2577ff;
        }

        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }

      .innovate_content {
        .dg {
          height: 54px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 400;
          color: #313131;
          .spanT {
            font-size: 13.5px;
            color: #a1a1a1;
          }
          .newstitle {
            width: 250px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }

          &:hover {
            color: #2577ff;
          }
        }
      }
    }

    .bottomt {
      padding: 0 30px 0 55px;
      background-color: #333333;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .bottom_bxt {
        .bottom_leftt {
          margin-top: 20px;

          .connection_Tt {
            margin-bottom: 20px;

            img {
              width: 16px;
              margin-right: 12px;
            }

            span {
              font-size: 13px;
              font-weight: bold;
              color: #ffffff;
            }
          }
        }
      }

      .bnt {
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        opacity: 0.6;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
      }
    }
  }

  .Computer_terminal {
    display: none;
  }
}
</style>
