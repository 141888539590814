<template>
	<div class="Computer_terminal">
		<Nav :changeSelectStyle="2" class="nav-home"></Nav>
		<div class="achievement_bj">
			<h1>新闻资讯</h1>
		</div>
		<div class="Part_three">
			<div class="Part_three_bx">
				<div class="xwlb">
					<div v-for="(item, index) of awxlist" :key="index" class="dgxw" @click="details(item.id, item)">
						<img :src="api.Dom + item.filePath" alt="" />
						<h3>{{ item.newsTitle }}</h3>
						<p class="p1">{{ item.remark }}</p>
						<p class="p2">{{ item.newsDate }}</p>
					</div>
				</div>

				<div class="fy">
					<keepAlive>
						<el-pagination :background="true" :default-page-size="pageSize" :total="total" class="mt-4" layout="prev, pager, next" small @current-change="handleCurrentChange" />
					</keepAlive>
				</div>
			</div>
		</div>
		<BottomPanel></BottomPanel>
		<BackTop />
	</div>
	<div class="Mobile_terminal">
		<!--    导航-->
		<ydnav></ydnav>
		<!--    新闻页标题-->
		<div class="achievement_bj">
			<h1>新闻资讯</h1>
		</div>
		<div class="Part_threet">
			<div class="Part_three_bx">
				<div class="Part_three_bx_btn">
					<span v-for="(item, index) of xwnav" :key="index" :class="{ changeStyleT: ind == index }" @click="qieH(index)">{{ item.name }}</span>
				</div>
				<div class="xwlb">
					<div v-for="(item, index) of awxlistt" :key="index" class="dgxw" @click="details(item.id, item)">
						<img :src="api.Dom + item.filePath" alt="" />
						<h3>{{ item.newsTitle }}</h3>
						<p class="p1">{{ item.remark }}</p>
						<p class="p2">{{ item.newsDate }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="bottomt">
			<div class="bottom_bxt">
				<div class="bottom_leftt">
					<div class="connection_Tt"><img alt="" src="../assets/images/telephone.png" /><span>0532 86768848</span></div>
					<div class="connection_Tt"><img alt="" src="../assets/images/mailbox.png" /><span>266555</span></div>
					<div class="connection_Tt"><img alt="" src="../assets/images/orientation.png" /><span>山东省青岛市黄岛区鹏湾路57号</span></div>
				</div>
			</div>
			<div>
				<p class="bnt">备案：鲁ICF备13025817号</p>
				<p class="bnt">鲁公网安备：37021002000075</p>
			</div>
		</div>
	</div>
</template>
<script setup>
import BackTop from "../components/BackTop.vue";
import ydnav from "@/components/ydnav.vue";
import Nav from "../components/Nav.vue"; //引入导航栏组件
import BottomPanel from "../components/Mybottom.vue"; //引入底部组件
import axios from "axios"; //引入axios
import api from "../api/api"; //引入cimos接口api
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router"; //引入vue路由
const router = useRouter(); //路由
const { currentRoute } = useRouter();
const xwnav = ref([
	{
		name: "新闻要览",
	},
]);
const ind = ref(0);
const qieH = (index) => {
	ind.value = index;
};
const awxlist = ref([]);
const awxlistt = ref([]);
const details = (id, item) => {
	let { href } = router.resolve(`/details?id=${id}`);
	window.open(href, "_blank");
	// router.push({path: "/details", query: {id: id}});
};
const pageIndex = ref(1);
const pageSize = ref(6);
const total = ref(0);
const handleCurrentChange = (val) => {
	pageIndex.value = val;
	NewsList();
};
const NewsList = () => {
	axios({
		method: "get",
		params: {
			pageSize: pageSize.value,
			pageIndex: pageIndex.value,
		},
		url: api.NewsList,
	}).then(({ data }) => {
		awxlist.value = data.list;
		total.value = data.total;
	});
};
onMounted(() => {
	scrollTo(0, 0);
	NewsList();
	axios({
		method: "get",
		url: api.NewsList,
	}).then(({ data }) => {
		awxlistt.value = data.list;
	});
});
</script>
<style lang="less" scoped>
.nav-home {
	position: absolute;
	background-color: #ffffff00;
}

.achievement_bj {
	height: 410px;
	background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xwzx.png");
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	align-items: center;
	margin-bottom: 104px;

	h1 {
		margin-left: 18.5%;
		font-size: 42px;
		font-weight: bold;
		color: #ffffff;
		font-family: Microsoft YaHei;
	}
}

.Part_three {
	.Part_three_bx {
		.Part_three_bx_btn {
			display: flex;
			justify-content: center;
			margin-bottom: 90px;

			span {
				padding: 27px 75px 27px 75px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #ffffff;
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #3d7effff;
			}

			.changeStyleT {
				background-color: #2577ff;
				color: #ffffff;
			}
		}

		.xwlb {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			.dgxw:nth-child(2) {
				margin-right: 27px;
				margin-left: 27px;
			}

			.dgxw:nth-child(4) {
				margin-bottom: 0px;
			}

			.dgxw:nth-child(5) {
				margin-bottom: 0px;
				margin-right: 27px;
				margin-left: 27px;
			}

			.dgxw:nth-child(6) {
				margin-bottom: 0px;
			}

			.dgxw {
				background-color: #ffffff;
				margin-bottom: 77px;
				width: 381px;
				height: 514px;
				cursor: pointer;
				box-shadow: 0 15px 30px #eeeeee;

				img {
					width: 100%;
					height: 254px;
				}

				h3 {
					height: 60px;
					width: 288px;
					padding-left: 28px;
					padding-top: 31px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #272727;
					line-height: 28px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.p1 {
					height: 36px;
					width: 315px;
					padding-left: 28px;
					padding-top: 26px;
					font-size: 14px;
					color: #737373;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.p2 {
					padding-left: 30px;
					padding-top: 57px;
					font-size: 14px;
					color: #898989;
				}

				&:hover .p2 {
					color: #2577ff;
				}

				&:hover .p1 {
					color: #2577ff;
				}

				&:hover h3 {
					color: #2577ff;
				}
			}
		}

		.fy {
			margin-bottom: 127px;
			margin-top: 93px;
			display: flex;
			justify-content: center;
			align-items: center;

			/deep/ .number {
				width: 34px;
				height: 34px;
			}

			/deep/ .is-active {
				background-color: #2577ff;
			}

			/deep/ .btn-next {
				width: 34px;
				height: 34px;
			}

			/deep/ .btn-prev {
				width: 34px;
				height: 34px;
			}
		}
	}
}

.Mobile_terminal {
	display: none;
}

@media (max-width: 1680px) and(min-width: 1440px) {
	.nav-home,
	/deep/ .bottom_left,
	/deep/ .right_sce {
		transform: scale(0.8);
	}
}

@media (max-width: 1439px) and(min-width: 1100px) {
	.nav-home,
	/deep/ .bottom_left,
	/deep/ .right_sce {
		transform: scale(0.8);
	}
}

@media screen and (max-width: 769px) {
	.Mobile_terminal {
		display: block;

		.achievement_bj {
			height: 200px;
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xwzx.png");
			background-repeat: no-repeat;
			background-position: center center;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			h1 {
				margin-left: 18.5%;
				font-size: 20px;
				font-weight: bold;
				color: #ffffff;
			}
		}

		.Part_threet {
			padding: 0 30px 0 30px;

			.Part_three_bx {
				.Part_three_bx_btn {
					display: flex;
					justify-content: center;
					margin-bottom: 35px;

					span {
						padding: 27px 75px 27px 75px;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #ffffff;
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #3d7effff;
					}

					.changeStyleT {
						background-color: #2577ff;
						color: #ffffff;
					}
				}

				.xwlb {
					margin: 0 auto;
					justify-content: flex-start;

					.dgxw {
						background-color: #ffffff;
						margin-bottom: 25px;
						height: 514px;
						cursor: pointer;
						box-shadow: 0 15px 30px #eeeeee;

						img {
							width: 100%;
							height: 254px;
						}

						h3 {
							padding-right: 28px;
							padding-left: 28px;
							padding-top: 31px;
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #272727;
							line-height: 28px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}

						.p1 {
							padding-right: 28px;
							padding-left: 28px;
							padding-top: 26px;
							font-size: 14px;
							color: #737373;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}

						.p2 {
							padding-left: 30px;
							padding-top: 57px;
							font-size: 14px;
							color: #898989;
						}

						&:hover .p2 {
							color: #2577ff;
						}

						&:hover .p1 {
							color: #2577ff;
						}
					}
				}
			}
		}

		.lxwm {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;

			h1 {
				color: #2577ff;
			}
		}

		.bottomt {
			background-color: #333333;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			.bottom_bxt {
				.bottom_leftt {
					margin-top: 12px;

					.connection_Tt {
						margin-bottom: 12px;

						img {
							width: 16px;
							margin-right: 12px;
						}

						span {
							font-size: 13px;
							font-weight: bold;
							color: #ffffff;
						}
					}
				}
			}

			.bnt {
				font-size: 13px;
				font-weight: bold;
				color: #ffffff;
				opacity: 0.6;
				display: flex;
				justify-content: center;
				margin-bottom: 15px;
			}
		}
	}

	.Computer_terminal {
		display: none;
	}
}
</style>
