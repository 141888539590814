// 测试环境域名
// const DomainName = "https://api.otgov.com/";
// const file = "http://file.otgov.com/";
// 正式环境域名
const file = "http://file.cimos.cn/";
const DomainName = "https://api.cimos.cn/"; //正式环境url
export default {
	xz: DomainName + "file/cimosFiles/",
	Dom: file,
	pdfurl: DomainName + "file/cimosFiles/pdf/view?filePath=",
	NewsList: DomainName + "zmqwebsite/web/news", //新闻及新闻详情
	menu: DomainName + "zmqwebsite/web/menu", //设备菜单
	productInfo: DomainName + "zmqwebsite/web/productInfo", //设备菜单内容
	position: DomainName + "zmqwebsite/web/position", //地图
	Dic: DomainName + "zmqwebsite/web/dic", //数智化创新制度分类列表
	system: DomainName + "zmqwebsite/web/system", //数智化创新制度列表及详情
	other: DomainName + "zmqwebsite/web/other", //其他
	downloadZip: DomainName + "zmqwebsite/web/downloadZip", //下载附件
	dgdzlist: DomainName + "zmqwebsite/web/fileDic", //顶规导则Pdf列表
	dgdzxq: DomainName + "zmqwebsite/web/fileDic", //顶规导则PDF详情
};
