<template>
  <div class="Computer_terminal">
    <!--导航栏-->
    <Nav :changeSelectStyle="1" class="nav_com"></Nav>
    <!-- 数智成果 -->
    <div class="achievement_bj"><h1>数智成果</h1></div>
    <div class="nrbf">
      <div class="nrbfbx">
        <div class="pdflist">
          <div
            v-for="(item, index) of state.pdflist"
            :key="index"
            :class="{ xzdg: state.xzdgindex === index }"
            class="pdflistdg"
            @click="djxz(index, item.id)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="pdft">
          <div class="vuePdfEmbed">
            <VuePdfEmbed :page="state.pageNum" :source="state.source" :style="scaleFun" class="vue-pdf-embed" height="807" width="571" />
          </div>
          <div class="xzfj" @click="downloadPDF" @mouseenter="handleMouseEnterT" @mouseleave="handleMouseLeaveT">
            下载附录 <img :src="state.img" />
          </div>
        </div>
        <div class="page-tool">
          <div class="page-tool-item" @click="lastPage"></div>
          <div class="page-tool-itemt" @click="nextPage"></div>
        </div>
      </div>
    </div>
    <!--bottom栏 -->
    <Mybottom></Mybottom>
    <BackTop />
  </div>
  <div class="Mobile_terminal">
    <!--    导航-->
    <ydnav></ydnav>
    <!--    新闻资讯-->
    <div class="achievement_bj">
      <h1>数智成果</h1>
    </div>
  </div>
</template>
<script setup>
import BackTop from "../components/BackTop.vue";
import ydnav from "@/components/ydnav.vue";
// import VuePdfEmbed from "vue-pdf-embed";
// import { getDocumentNumPages } from "pdfjs-dist/es5/build/pdf.js"; //获得总页数
// import * as PDFJS from "pdfjs-dist/legacy/build/pdf.js";
// import * as workerSrc from "pdfjs-dist/build/pdf.worker.entry.js";
import axios from "axios";
import api from "../api/api";
import Nav from "@/components/Nav.vue";
import Mybottom from "@/components/Mybottom.vue";
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router"; //引入vue路由

const active = ref(0);
const router = useRouter(); //路由
const { currentRoute } = useRouter();
const route = currentRoute.value;
const state = reactive({
  pdflist: [],
  xzdgindex: 0,
  source: "", //预览pdf文件地址
  pageNum: 1, //当前页面
  scale: 1, // 缩放比例
  numPages: 500, // 总页数
  report: "",
  img: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png",
  id: "",
  src: "",
});
const handleMouseEnterT = () => {
  state.img = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xztto.png";
};
const handleMouseLeaveT = () => {
  state.img = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png";
};
const scaleFun = reactive({
  transform: "scale(" + state.scale + ")",
});
// // 获取上一页
const lastPage = () => {
  if (state.pageNum > 1) {
    state.pageNum--;
  }
};
// // 获取下一页
const nextPage = () => {
  if (state.pageNum < state.numPages) {
    state.pageNum++;
  }
};
// // 下载pdf
const downloadPDF = () => {
  window.open(api.xz + "download?filePath=" + state.src + "&fileName=" + encodeURIComponent(state.report));
};
const loadPdfHandler = (e) => {
  state.currentPage = 1; // 加载的时候先加载第一页
};
const djxz = (index, id) => {
  state.id = id;
  state.xzdgindex = index;
  axios({
    method: "get",
    url: api.dgdzxq + "/" + id,
  }).then(({ data }) => {
    state.src = data.val.filePath;
    state.source = "https://api.otgov.com/file/cimosFiles/pdf/view?filePath=" + data.val.filePath;
    state.report = data.val.fileName;
  });
};
const TopGaugeDetails = async () => {
  await axios({
    method: "get",
    url: api.dgdzlist + "?pid=" + route.query.dg,
  }).then(({ data }) => {
    state.pdflist = data.list;
  });
};
// const getTotalPages =  () => {
//     const pdf =  getDocumentNumPages(state.source);
//     state.numPages = pdf.numPages;
// };
onMounted(() => {
  TopGaugeDetails();
  djxz(Number(route.query.index), route.query.id);
  scrollTo(0, 0);
});
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.nav_com {
  position: absolute;
}

.achievement_bj {
  height: 410px;
  background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szzm.png");
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  margin-bottom: 84px;

  h1 {
    margin-left: 18.5%;
    font-size: 42px;
    font-weight: bold;
    color: #ffffff;
    font-family: Microsoft YaHei;
  }
}

.nrbf {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;

  .nrbfbx {
    display: flex;

    .pdflist {
      margin-right: 179px;

      .pdflistdg {
        width: 320px;
        height: 64px;
        background-color: #efefef;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #434343;
        display: flex;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 6px;
      }

      .xzdg {
        background-color: #2577ff;
        color: #ffffff;
      }
    }

    .pdft {
      margin-right: 89px;
      width: 571px;
      height: 807px;

      .vuePdfEmbed {
        flex: 1;
        display: flex;
        height: 100%;
        flex-direction: column;
        margin-bottom: 80px;
        box-shadow: 0px 0px 5px 5px #eaeaea;
      }
    }
  }
}

.page-tool {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 174px;

  .page-tool-item {
    width: 41px;
    height: 38px;
    display: flex;
    background-image: url("../assets/z.png");
    margin-bottom: 11px;

    &:hover {
      background-image: url("../assets/y.png");
      transform: rotate(180deg);
    }

    cursor: pointer;
  }

  .page-tool-itemt {
    width: 41px;
    height: 38px;
    display: flex;
    background-image: url("../assets/z.png");
    transform: rotate(180deg);

    &:hover {
      background-image: url("../assets/y.png");
      transform: rotate(0deg);
    }
  }
}

.xzfj {
  width: 240px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #7e7e7e;
  font-size: 14px;
  font-weight: bold;
  color: #434343;
  margin: 0 auto;

  &:hover {
    background-color: #2577ff;
    color: #ffffff;
    border: 1px solid #2577ff;
  }

  img {
    margin-left: 20px;
  }
}

.Mobile_terminal {
  display: none;
}

@media (max-width: 1680px) and(min-width: 1440px) {
  .nav_com,
  .nrbf,
  .page-tool,
  xzfj {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 769px) {
  .Mobile_terminal {
    display: block;

    .achievement_bj {
      height: 200px;
      background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szzm.png");
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      h1 {
        margin-left: 18.5%;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }

  .Computer_terminal {
    display: none;
  }
}
</style>
