<template>
  <div class="Computer_terminal">
    <!--  导航栏-->
    <Nav class="nav-home" :changeSelectStyle="6"></Nav>
    <!--  地图·-->
    <div class="bj">
      <div id="map" ref="mapContainer"></div>
      <div class="lbbtn" @click="dj">位置列表 <img class="jt" :src="bd.imgs" /></div>
      <div v-show="show" class="listbjt">
        <span class="qx" @click="qx"><img src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/qx.png" /></span>
        <div class="lb">
          <div class="listdg" ref="reft" v-for="(item, index) of list" :class="{ mp: mpt == index }" :key="index" @click="itenClick(index, item)">
            {{ item.ptitle }}
          </div>
        </div>
      </div>
    </div>

    <!--  底部栏-->
    <BottomPanel></BottomPanel>
    <BackTop />
  </div>
</template>
<script setup>
import BackTop from "../components/BackTop.vue";
import ydnav from "@/components/ydnav.vue";
import Nav from "../components/Nav.vue"; //引入导航栏组件
import BottomPanel from "../components/Mybottom.vue"; //引入底部组件
import axios from "axios"; //引入axios
import api from "../api/api"; //引入cimos接口api
import { ref, reactive, onMounted } from "vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css"; //
import zmq from "../components/zmq.json"; //引入自贸区轮廓图坐标
const mpt = ref(null);
const list = ref([]); //地图数据
const mapContainer = ref(null); //地图实例
const map = ref(null); //地图
const show = ref(false); //位置列表显示隐藏，默认false(隐藏)
const bd = reactive({
  markers: [],
  imgs: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png",
});
const dj = () => {
  show.value = !show.value;
  if (show.value == false) {
    bd.imgs = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png";
  } else {
    bd.imgs = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/sjt.png";
  }
};
const qx = () => {
  show.value = false;
  bd.imgs = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png";
};
const reft = ref(null);
// 默认标点图标
const myIcon = L.icon({
  className: "myIcon",
  iconUrl: require("../assets/dw.png"),
  iconSize: [28, 25],
  iconAnchor: [15, 10],
});
const NewmyIcon = L.icon({
  className: "NewmyIcon",
  iconUrl: require("../assets/images/bd.png"),
  iconSize: [28, 25],
  iconAnchor: [15, 10],
});
// 点击获取地图标点信息
const itenClick = (index, item) => {
  window.open(item.link);
};
const axiosMap = () => {
  // 创建地图实例
  let map = L.map("map", {
    zoom: 12.4, // 设置缩放级别
    center: [36.04, 120.14], // 地图中心
    zoomControl: false, // + - 按钮
    doubleClickZoom: true, // 双击放大
    attributionControl: false, // 右下角leaflet标识
    zoomAnimation: false,
  });
  // 添加自贸区轮廓图层
  L.geoJSON(zmq, {
    style: {
      // stroke: true, // 是否显示轮廓线
      color: "#246FF2",
      fillColor: "#246FF2",
      weight: 2,
    },
  }).addTo(map);
  //循环进行多个标点
  var tooltipOptions = {
    className: "my-custom-tooltip",
    direction: "top",
    offset: [0, -10], // 调整提示框的位置
  };
  for (let i = 0; i < list.value.length; i++) {
    // 创建标点
    bd.markers.push(L.marker([list.value[i].plat, list.value[i].plon]).addTo(map).bindTooltip(list.value[i].ptitle, tooltipOptions).setIcon(myIcon));
  }

  // 添加点击事件监听器
  bd.markers.forEach(function (marker, index) {
    marker.on("mouseover", function (event) {
      mpt.value = index;
      // 获取当前点击的标记
      var currentMarker = this;
      // // 遍历所有标记，将非当前标记恢复为默认图标
      bd.markers.forEach(function (marker) {
        if (marker !== currentMarker) {
          marker.setIcon(myIcon);
        }
      });
      // 将当前标记设置为其他图标
      marker.setIcon(NewmyIcon);
    });
  });

  // 添加点击事件监听器
  bd.markers.forEach(function (marker, index) {
    marker.on("click", function () {
      window.open(list.value[index].link);
    });
  });
};
onMounted(() => {
  // 请求地图数据
  axios({ method: "get", url: api.position }).then(({ data }) => {
    list.value = data.list;
    axiosMap();
  });
  scrollTo(0, 0);
});
</script>
<style lang="less" scoped>
.nav-home {
  position: absolute;
}

.bj {
  padding: 81px 0 79px 0;
  width: 100%;
  background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szrm.png");
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  #map {
    cursor: pointer;
    width: 681px;
    height: 588px;
    opacity: 1;
    background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szrm.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ffffff00;
  }
}

.lbbtn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: #2577ff;
  width: 179px;
  height: 36px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 89px;
  left: 360px;
  z-index: 111111;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f4f2e9;
  padding-top: 20px;
  padding-left: 32px;
  padding-right: 30px;

  .jt {
    width: 20px;
    height: 20px;
  }
}

.listbjt {
  cursor: pointer;
  background-color: rgba(37, 119, 255, 0.79);
  width: 870px;
  height: 361px;
  overflow: auto;
  position: absolute;
  z-index: 111111;
  bottom: 169px;
  left: 360px;
  padding: 30px;

  .qx {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 6px;
    right: 5px;

    img {
      width: 15px;
      height: 15px;
    }
  }

  .lb {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    text-align: 10px;
    .mp {
      font-weight: bolder !important;
    }
    .listdg {
      display: flex;
      height: 16px;
      width: 20%;
      cursor: pointer;
      float: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f4f2e9;
      margin-bottom: 35px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-left: 25px;
    }
  }
}

/deep/ .leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #2c326d;
  color: #ffffff;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  height: 35px;
}

/deep/ .leaflet-popup-tip-container {
  display: none;
}

/deep/ .leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 0px;
}

/deep/ .leaflet-popup-content {
  margin: 0;
  font-size: 1.08333em;
  min-height: 1px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

/deep/ .leaflet-container a.leaflet-popup-close-button {
  display: none;
}

/deep/ .leaflet-popup {
  opacity: 0.86 !important;
}

@media (max-width: 1680px) and(min-width: 1440px) {
  .nav-home,
  /deep/ .bottom_left,
  /deep/ .right_sce {
    transform: scale(0.8);
  }
  .listbjt {
    cursor: pointer;
    background-color: rgba(37, 119, 255, 0.79);
    width: 570px;
    height: 300px;
    overflow: auto;
    position: absolute;
    z-index: 111111;
    bottom: 169px;
    left: 360px;
    padding: 30px;
  }
}

@media (max-width: 1439px) and(min-width: 1100px) {
  .nav-home,
  /deep/ .bottom_left,
  /deep/ .right_sce {
    transform: scale(0.8);
  }
  .listbjt {
    cursor: pointer;
    background-color: rgba(37, 119, 255, 0.79);
    width: 570px;
    height: 300px;
    overflow: auto;
    position: absolute;
    z-index: 111111;
    bottom: 169px;
    left: 360px;
    padding: 30px;
  }
}
/deep/.my-custom-tooltip {
  background: #2c326d;
  color: #ffffff;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  //   height: 35px;
  border: #2c326d;
}
/deep/.leaflet-tooltip {
  border-radius: 0px;
}
/deep/.leaflet-tooltip-top:before {
  display: none;
}
</style>
