<template>
	<!--导航栏-->
	<div class="logo">
		<div class="logoDiv">
			<img src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/logo.png" />
		</div>
		<span>
			<el-icon color="#FFFFFF" :size="25" @click="drawer2 = true"><Fold /></el-icon>
		</span>
		<el-drawer v-model="drawer2" :direction="direction" size="80%" :show-close="false">
			<template #header="{ close, titleId, titleClass }">
				<h4 :id="titleId" :class="titleClass"></h4>
				<el-icon color="#ffffff" @click="drawer2 = false" :size="25"><Expand /></el-icon>
			</template>
			<div class="nav">
				<p v-for="(item, index) of state.list" :key="index" @click="router.push(item.path)">
					{{ item.name }}
				</p>
				<a href="https://oa.cimos.cn/oa/zmq_login.html">登录</a>
			</div>
		</el-drawer>
	</div>
</template>
<script setup>
import { Fold, Expand } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
import api from "../api/api";
import { reactive, onMounted, ref } from "vue";
import { useRouter } from "vue-router"; //引入vue路由
const router = useRouter(); //路由
const state = reactive({
	list: [
		{
			name: "首页",
			path: "/",
		},
		{
			name: "数智成果",
			path: "/achievement",
		},
		{
			name: "新闻资讯",
			path: "/message",
		},
		{
			name: "数智自贸",
			path: "/FreeTrade",
		},
		{
			name: "一网通办",
			path: "/GeneralOffice",
		},
		{
			name: "关于我们",
			path: "/AboutUs",
		},
		{
			name: "云上自贸",
			path: "/ydszzm",
		},
	],
});
const drawer2 = ref(false);
function cancelClick() {
	drawer2.value = false;
}
onMounted(() => {});
</script>
<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}
a {
	text-decoration: none;
}
.logo {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 8vh;
	//   background-color: #2577ff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.logoDiv {
		margin-left: 30px;
		img {
			width: 25vw;
		}
	}
	span {
		padding-right: 30px;
		width: 25px;
		height: 25px;
	}
	/deep/.el-drawer.rtl {
		background-color: #2577ffa6;
		z-index: 1;
	}
	.nav {
		p {
			color: #ffffff;
			margin-bottom: 20px;
			font-size: 18px;
			margin-left: 10px;
		}
		a {
			color: #ffffff;
			font-size: 18px;
			margin-left: 10px;
		}
	}
}

/deep/ .el-drawer__header {
	padding-right: 30px;
}
</style>
