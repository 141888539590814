<template>
	<div class="bottom">
		<div class="bottom_bx">
			<div class="bottom_left">
				<div class="Nav_text"><a @click="home">首页</a> |<a @click="message" href="">新闻动态</a> |<a @click="FreeTrade">数智自贸</a> |<a @click="GeneralOffice">一网通办</a> |<a @click="AboutUs">关于我们</a> |<a @click="FinancialMedium">云上自贸</a></div>
				<div class="connection">
					<div class="connection_T"><img src="../assets/images/telephone.png" alt="" /><span>0532 86768848</span></div>
					<div class="connection_T"><img src="../assets/images/mailbox.png" alt="" /><span>266555</span></div>
					<div class="connection_T"><img src="../assets/images/orientation.png" alt="" /><span>山东省青岛市黄岛区鹏湾路57号</span></div>
				</div>
			</div>
			<div class="bottom_right">
				<div class="right_sce">
					<el-select v-model="selectValue" class="m-2" placeholder="友情链接" popper-class="detailDialog_select-popper" @change="change()">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
					</el-select>
					<div class="ewm">
						<img src="	https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/ewm.png" alt="" />
						<p>微信公众号</p>
					</div>
				</div>
			</div>
		</div>
		<p class="bn">备案：鲁ICF备13025817号 鲁公网安备：37021002000075</p>
	</div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router"; //引入vue路由
const router = useRouter(); //路由
const selectValue = ref("");
const home = () => {
	let { href } = router.resolve(`/`);
	window.open(href, "_blank");
};
const message = () => {
	let { href } = router.resolve(`/message`);
	window.open(href, "_blank");
};
const FreeTrade = () => {
	let { href } = router.resolve(`/FreeTrade`);
	window.open(href, "_blank");
};
const GeneralOffice = () => {
	let { href } = router.resolve(`/GeneralOffice`);
	window.open(href, "_blank");
};
const AboutUs = () => {
	let { href } = router.resolve(`/AboutUs`);
	window.open(href, "_blank");
};
const FinancialMedium = () => {
	let { href } = router.resolve(`/FinancialMedium`);
	window.open(href, "_blank");
};
const options = [
	{
		value: "http://qdftz.qingdao.gov.cn/zcfg_115/qywj_115/",
		label: "青岛自贸片区官网",
	},
	{
		value: "http://qdsxzspfwj.qingdao.gov.cn/",
		label: "行政审批网",
	},
	{
		value: "http://dsj.qingdao.gov.cn/",
		label: "青岛市大数据局",
	},
];
const change = () => {
	window.open(selectValue.value);
};
</script>
<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}
.bottom {
	height: 256px;
	background-color: #333333;
	.bottom_bx {
		margin: 0 auto;
		width: 1200px;
		display: flex;
		justify-content: space-between;
		.bottom_left {
			.Nav_text {
				padding-top: 46px;
				color: #ffffff;
				margin-bottom: 40px;
				a:nth-child(1) {
					margin-left: 0;
				}
				a:nth-last-child(1) {
					margin-right: 0;
				}
				a {
					margin-right: 16px;
					margin-left: 16px;
					font-size: 14px;
					font-weight: bold;
					color: #ffffff;
					cursor: pointer;
				}
			}
			.connection {
				.connection_T:nth-last-child(1) {
					margin-bottom: 0;
				}
				.connection_T {
					margin-bottom: 18px;
					img {
						margin-right: 12px;
					}
					span {
						font-size: 13px;
						font-weight: 400;
						color: #ffffff;
					}
				}
			}
		}
		.bottom_right {
			padding-top: 47px;
			.right_sce {
				display: flex;
				.ewm {
					margin-left: 47px;
					display: flex;
					flex-direction: column;
					align-items: center;
					img {
						width: 106px;
						margin-bottom: 12px;
					}
					p {
						font-size: 13px;
						font-weight: bold;
						color: #ffffff;
					}
				}
			}
		}
	}
	.bn {
		font-size: 13px;
		font-weight: bold;
		color: #ffffff;
		opacity: 0.6;
		display: flex;
		justify-content: center;
	}
}
</style>
