<template></template>

<script setup>
import {onMounted} from "vue";

onMounted(() => {
  window.location.href = "http://117.73.9.220:8866/web/zimaoqu/ywtb.do";
});
</script>
<style scoped></style>
