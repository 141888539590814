<template>
  <div class="Computer_terminal">
    <!--导航栏-->
    <Nav :changeSelectStyle="1" class="nav_com"></Nav>
    <!-- 数智成果 -->
    <div class="achievement_bj"><h1>数智成果</h1></div>
    <!-- 内容 -->
    <!--    内容-->
    <div class="bx">
      <div class="Part_three">
        <!-- 一级标题 -->
        <div class="bt">
          <div class="tit">
            <h2
              v-for="(item, index) of Field_set.Primary_navigation"
              :key="index"
              :class="{ changeStyleT: Field_set.changeSelectStyleT == index }"
              @click="styleClassT(index, item)"
            >
              {{ item.menuName }}
            </h2>
          </div>
        </div>
        <div class="Part_three_content">
          <!-- 二级标题 -->
          <div class="Part_three_content_left">
            <div
              v-for="(item, index) of Field_set.secondary_menu"
              :key="index"
              :class="{ changeStyle: Field_set.lefttitstyle == index }"
              class="left_dg"
              @click="styleClass(index, item.id)"
            >
              <p>{{ item.dicName || item.title }}</p>
              <img class="icon" src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png" />
            </div>
          </div>
          <!-- 核心内容 -->
          <div v-if="Field_set.changeSelectStyleT == 0" class="demo-collapse" style="margin-left: 20px; width: 74%">
            <!-- <el-collapse v-model="activeNames" accordion>
							<el-collapse-item  :name="index" :title="" > </el-collapse-item>
						</el-collapse> -->
            <div class="dgxx" v-for="(item, index) of Field_set.listArray" @click="dy(index, item)">
              {{ item.newsTitle }}
              <div class="back"></div>
              <!-- <img src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/right.png" alt="" /> -->
            </div>
          </div>
          <div v-if="Field_set.changeSelectStyleT == 2 || Field_set.changeSelectStyleT == 1" class="vuePdfEmbed">
            <div class="pdfxq">
              <VuePdfEmbed :page="state.pageNum" :source="state.source" :style="scaleFun" class="vue-pdf-embed" height="807" width="571" />
            </div>
            <div class="page-tool">
              <div class="page-tool-itemt" @click="nextPage"></div>
              <div class="page-tool-item" @click="lastPage"></div>
            </div>
          </div>
        </div>
        <div
          v-if="Field_set.changeSelectStyleT == 2"
          class="xzfj"
          @click="downloadPDF"
          @mouseenter="handleMouseEnterT"
          @mouseleave="handleMouseLeaveT"
        >
          下载附录 <img :src="state.img" />
        </div>
      </div>
    </div>

    <!-- bottom栏 -->
    <Mybottom class="My_bottom"></Mybottom>
    <BackTop />
  </div>
</template>

<script setup>
import BackTop from "../components/BackTop.vue";
// import VuePdfEmbed from "vue-pdf-embed";
// import { createLoadingTask } from "vue3-pdfjs"; // 获得总页数
import { ElMessage } from "element-plus";
import axios from "axios";
import api from "../api/api";
import Nav from "@/components/Nav.vue";
import Mybottom from "@/components/Mybottom.vue";
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router"; //引入vue路由
const router = useRouter(); //路由
const { currentRoute } = useRouter();
const route = currentRoute.value;
// 字段集合
const Field_set = reactive({
  // 顶规列表id
  DGid: "",
  // 一级菜单标题
  Primary_navigation: [
    {
      menuName: "数智化制度创新",
    },
    {
      menuName: "数智自贸顶规",
    },
    {
      menuName: "数智化运营导则",
    },
  ],
  //一级菜单索引(点击添加样式使用)
  changeSelectStyleT: 0,
  // 二级菜单
  secondary_menu: [],
  // 二级菜单索引(点击添加样式使用)
  lefttitstyle: 0,
  // 三级列表
  listArray: [],
});
const state = reactive({
  pdflist: [],
  xzdgindex: 0,
  source: "", //预览pdf文件地址
  pageNum: 1, //当前页面
  scale: 1, // 缩放比例
  numPages: 0, // 总页数
  report: "",
  img: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png",
  id: "",
  src: "",
});
const downloadPDF = () => {
  window.open(api.xz + "download?filePath=" + state.src + "&fileName=" + encodeURIComponent(state.report));
};
//导则的下载按钮移入切换图标
const handleMouseEnterT = () => {
  state.img = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xztto.png";
};
// 导则的下载按钮移出切换图标
const handleMouseLeaveT = () => {
  state.img = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png";
};
const scaleFun = reactive({
  transform: "scale(" + state.scale + ")",
});
// 一级菜单点击事件
const styleClassT = (index, item) => {
  Field_set.changeSelectStyleT = index;
  Field_set.lefttitstyle = 0;
  //获取二级菜单内容
  // 判断一级内容，展示二三级内容
  if (Field_set.changeSelectStyleT == 0) {
    state.pageNum = 1;
    axios({
      method: "get",
      url: api.Dic,
    }).then(({ data }) => {
      Field_set.secondary_menu = data.list;
      styleClass(0, Field_set.secondary_menu[0].id);
    });
  } else if (Field_set.changeSelectStyleT == 1) {
    state.pageNum = 1;
    axios({
      method: "get",
      url: api.other + "?type=4",
    }).then(({ data }) => {
      axios({
        method: "get",
        url: api.dgdzlist + "?pid=" + data.list[0].id,
      }).then(({ data }) => {
        Field_set.secondary_menu = data.list;
        if (route.query.type == 1) {
          styleClass(Number(route.query.index), data.list[route.query.index].id);
        } else {
          styleClass(0, data.list[0].id);
        }
      });
    });
  } else if (Field_set.changeSelectStyleT == 2) {
    state.pageNum = 1;
    axios({
      method: "get",
      url: api.other + "?type=5",
    }).then(({ data }) => {
      axios({
        method: "get",
        url: api.dgdzlist + "?pid=" + data.list[0].id,
      }).then(({ data }) => {
        console.log(data);
        Field_set.secondary_menu = data.list;
        if (route.query.type == 2) {
          styleClass(Number(route.query.index), data.list[route.query.index].id);
        } else {
          styleClass(0, data.list[0].id);
        }
      });
    });
  }
};
// 二级菜单点击事件
const styleClass = (index, id) => {
  Field_set.lefttitstyle = index;
  if (Field_set.changeSelectStyleT == 0) {
    axios({
      method: "get",
      url: api.system + "?classification=" + id,
    }).then(({ data }) => {
      Field_set.listArray = data.list;
    });
  } else if (Field_set.changeSelectStyleT == 1) {
    state.pageNum = 1;
    state.id = id;
    state.xzdgindex = index;
    axios({
      method: "get",
      url: api.dgdzxq + "/" + id,
    }).then(({ data }) => {
      state.src = data.val.filePath;
      state.source = api.pdfurl + data.val.filePath;
      state.report = data.val.fileName;
      state.pageNum = 1;
      // 加载异步任务
      const loadingTask = createLoadingTask({
        url: state.source,
        cMapUrl: "../assets/cmaps", //引入pdf.js字体
        cMapPacked: true,
      });
      // 载入pdf后获取页数
      loadingTask.promise.then((pdf) => {
        state.numPages = pdf.numPages;
      });
    });
  } else if (Field_set.changeSelectStyleT == 2) {
    state.id = id;
    state.xzdgindex = index;
    axios({
      method: "get",
      url: api.dgdzxq + "/" + id,
    }).then(({ data }) => {
      state.src = data.val.filePath;
      state.source = api.pdfurl + data.val.filePath;
      state.report = data.val.fileName;
      state.pageNum = 1;
      // 加载异步任务
      const loadingTask = createLoadingTask({
        url: state.source,
        cMapUrl: "../assets/cmaps", //引入pdf.js字体
        cMapPacked: true,
      });
      // 载入pdf后获取页数
      loadingTask.promise.then((pdf) => {
        state.numPages = pdf.numPages;
      });
    });
  }
};
// 三级列表点击事件
const dy = (index, item) => {
  let { href } = router.resolve(`/innovate?id=${item.id}`);
  window.open(href, "_blank");
};
// // 获取上一页
const lastPage = () => {
  if (state.pageNum > 1) {
    state.pageNum--;
  } else {
    ElMessage({
      message: "已经是首页",
      type: "warning",
    });
  }
};
// // 获取下一页
const nextPage = () => {
  if (state.pageNum < state.numPages) {
    state.pageNum++;
  } else {
    ElMessage({
      message: "已经是尾页",
      type: "warning",
    });
  }
};
onMounted(() => {
  styleClassT(route.query.type);

  scrollTo(0, 0);
});
</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}
.nav_com {
  position: absolute;
}
.achievement_bj {
  height: 410px;
  background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/achievement.png");
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;

  h1 {
    margin-left: 18.5%;
    font-size: 42px;
    font-weight: bold;
    color: #ffffff;
    font-family: Microsoft YaHei;
  }
}
.bx {
  width: 1200px;
  margin: 0 auto;

  .Part_three {
    .bt {
      padding-top: 86px;
      width: 100%;
      padding-bottom: 1px;
      border-bottom: 1px solid #ababab;
      margin-bottom: 97px;

      .tit {
        display: flex;
        justify-content: center;

        h2 {
          cursor: pointer;
          padding: 17px 44px;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #313333;
        }

        .changeStyleT {
          background-color: #2577ff;
          color: #ffffff;
        }
      }
    }

    .Part_three_content {
      display: flex;
      margin-bottom: 224px;

      .Part_three_content_left {
        float: left;

        .left_dg {
          cursor: pointer;
          width: 289px;
          height: 84px;
          font-size: 18px;
          display: flex;
          align-items: center;
          padding-left: 32px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #434343;
          background-color: #ececec;
          margin-bottom: 6px;
          justify-content: space-between;
          p {
            width: 210px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          .icon {
            display: none;
          }
        }

        .changeStyle {
          background-color: #2577ff;
          color: #ffffff;

          .icon {
            width: 20px;
            height: 20px;
            display: block;
            margin-right: 30px;
          }
        }
      }

      .Part_three_content_right {
        width: 775px;

        img {
          margin-bottom: 57px;
        }

        h1 {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #272727;
          margin-bottom: 28px;
        }

        .po {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2577ff;
          margin-bottom: 41px;
        }

        .pt {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #272727;
          line-height: 32px;
          margin-bottom: 55px;
        }

        h2 {
          font-size: 21px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #3d7eff;
          margin-bottom: 32px;
        }

        .zyzfnr {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 28px;

          .zfnr:nth-child(1) {
            margin-left: 0px;
          }

          .zfnr:nth-child(4) {
            margin-left: 0px;
          }

          .zfnr {
            padding: 10px 17px 11px 18px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #272727;
            background-color: #f3f3f3;
            box-sizing: border-box;
            border-left: 3px solid #3d7eff;
            margin-left: 18px;
            margin-bottom: 28px;
          }
        }

        h3 {
          font-size: 21px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #3d7eff;
          margin-bottom: 27px;
        }

        ol,
        li {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #272727;
          line-height: 32px;
          margin-left: 10px;
        }
      }
    }
  }
}
.demo-collapse {
  .dgxx:nth-child(1) {
    border-top: 1px solid #f3f4f8;
  }
  .dgxx {
    cursor: pointer;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    // background-color: #f1f1f1;
    background-color: #ffffff;
    // margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #f3f4f8;
    .back {
      background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/right.png");
      background-size: 15px;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
    }
    &:hover {
      background-color: #2577ff;
      color: #ffffff;
    }
    &:hover .back {
      background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png");
      background-size: 15px;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
    }
  }
}
.vuePdfEmbed {
  display: flex;

  .pdfxq {
    flex: 1;
    display: flex;
    width: 750px;
    height: 895px;
    flex-direction: column;
    margin-bottom: 80px;
    box-shadow: 0px 0px 5px 5px #eaeaea;
    align-items: center;
    margin-left: 50px;
  }
  .page-tool {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-top: 808.5px;
    margin-left: 20px;
    .page-tool-item {
      width: 41px;
      height: 38px;
      display: flex;
      background-image: url("../assets/z.png");
      margin-bottom: 11px;

      &:hover {
        background-image: url("../assets/y.png");
        transform: rotate(180deg);
      }

      cursor: pointer;
    }

    .page-tool-itemt {
      width: 41px;
      height: 38px;
      display: flex;
      background-image: url("../assets/z.png");
      transform: rotate(180deg);

      &:hover {
        background-image: url("../assets/y.png");
        transform: rotate(0deg);
      }
    }
  }
}
.xzfj {
  width: 240px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #7e7e7e;
  font-size: 14px;
  font-weight: bold;
  color: #434343;
  margin: -230px auto;
  margin-left: 630px;
  margin-bottom: 50px;

  &:hover {
    background-color: #2577ff;
    color: #ffffff;
    border: 1px solid #2577ff;
  }

  img {
    margin-left: 20px;
  }
}
@media (max-width: 1680px) and(min-width: 1440px) {
  .nav_com,
  .bx {
    transform: scale(0.9);
    .Part_three {
      .bt {
        padding-top: 0;
        width: 100%;
        padding-bottom: 1px;
        border-bottom: 1px solid #ababab;
        margin-bottom: 97px;

        .tit {
          display: flex;
          justify-content: center;

          h2 {
            cursor: pointer;
            padding: 17px 44px;
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #313333;
          }

          .changeStyleT {
            background-color: #2577ff;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media (max-width: 1439px) and(min-width: 1100px) {
  .nav_com,
  .bx {
    transform: scale(0.9);
    .Part_three {
      .bt {
        padding-top: 0;
        width: 100%;
        padding-bottom: 1px;
        border-bottom: 1px solid #ababab;
        margin-bottom: 97px;

        .tit {
          display: flex;
          justify-content: center;

          h2 {
            cursor: pointer;
            padding: 17px 44px;
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #313333;
          }

          .changeStyleT {
            background-color: #2577ff;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
