<template>
	<div class="Computer_terminal">
		<!--导航栏-->
		<Nav :changeSelectStyle="0" class="nav_com"></Nav>
		<!--背景视频-->
		<nut-video :options="state.options" :source="state.source"></nut-video>
		<!--新闻要览-->
		<div class="News_highlights">
			<!-- 新闻要览居中部分-->
			<div class="News_highlights_Type_center">
				<h1 class="News_highlights_h1">新闻要览 <span @click="router.push('/message')">查看更多</span></h1>
				<div class="News_highlights_content">
					<div class="News_highlights_Type_center_left" @click="ledtxq()">
						<img :src="state.News_highlights_o != null ? api.Dom + state.News_highlights_o.filePath : ''" alt="" />
						<h1>{{ state.News_highlights_o != null ? state.News_highlights_o.newsTitle : "" }}</h1>
						<span>{{ state.News_highlights_o != null ? state.News_highlights_o.newsDate : "" }}</span>
					</div>
					<div class="News_highlights_Type_center_right">
						<div v-for="(item, index) of state.News_highlights" :key="index" class="single" @click="details(item.id)">
							<div class="single_left">
								<span class="span_t">{{ item.newsDate != null ? item.newsDate.slice(5, 10).replace(/-/g, "/") : "" }}</span>
								<span class="span_o">{{ item.newsDate != null ? item.newsDate.slice(0, 4) : "" }}</span>
							</div>
							<img alt="" src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/PartingLine.png" />
							<div class="single_right">
								<h1>{{ item.newsTitle }}</h1>
								<p>{{ item.remark }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--设备菜单&设备简介-->
		<div class="Device_menu">
			<div class="Device_menu_Type_center">
				<!--  1-->
				<div class="Device_menu_Type_center_left_content">
					<div v-for="(item, index) of state.Device_menu" :key="index" :class="{ active: state.Device_menu_left_index === index }" class="left_single_Device_menu" @click="Device_menu_index(index, item)">
						{{ item.menuName }}
					</div>
				</div>
				<!--  2-->
				<div class="Device_menu_Type_center_middle_content">
					<div class="Device_menu_Type_center_middle_content_bx">
						<div v-for="(item, index) of state.Device_menu_middle" :key="index" :class="{ activeT: state.Device_menu_middle_index === index }" class="middle_single_Device_menu" @click="Device_menu_middle_index(index, item.id)">{{ item.menuName }}</div>
					</div>
				</div>
				<!--  3-->
				<div class="Device_menu_Type_center_middle_contentt">
					<div class="Device_menu_Type_center_middle_content_bx" v-if="state.isRresh">
						<div v-for="(item, index) of state.Device_menu_right" :key="index" :class="{ activeTT: state.Device_menu_middle_right_index === index }" class="middle_single_Device_menu" @click="Device_menu_right(index, item)">
							{{ item.title }}
						</div>
					</div>
					<div v-if="state.total > 6" class="btn">
						<div class="left" @click="lfetBtn"></div>
						<div class="right" @click="rightBtn"></div>
					</div>
				</div>
				<!--  4-->
				<div class="Device_menu_Type_center_right_content">
					<h1>{{ state.Device_menu_right_T ? state.Device_menu_right_T.title : "" }}</h1>
					<p>{{ state.Device_menu_right_T ? state.Device_menu_right_T.remark : "" }}</p>
					<!--<p>通过建设特种设备监管服务平台，通过直观、便捷、高效的数据统计分析，实现特种设备使用单位分布及特种设备信息的一图集成、精准定位。通过建立有效的信用信息数据地图预警分析，构建“横向到边、纵向到底”的网络化管理体系，实现监管全域感知。利用智能物联网技术，构建流动作业特种设备轨迹监管、异常状态告警等监管体系，实现特种设备目标定位、工作状态、指标参数的实时监测。</p>-->
					<span v-if="state.totalt > 0" @click="See_more">查看更多</span>
				</div>
			</div>
		</div>
		<!--云上自贸&数智融媒-->
		<div class="Free_trade_on_the_cloud">
			<!--  云上自贸  -->
			<div class="Free_trade_on_the_cloudT" @click="router.push('/FinancialMedium')">
				<div class="left">
					<img alt="" src="../assets/images/AboveT.png" />
					<p>云上自贸</p>
				</div>
			</div>
			<!--  数智融媒  -->
			<div class="Data_intelligence_and_financial_media">
				<div class="right">
					<img alt="" src="../assets/images/NumberT.png" />
					<p>数智融媒</p>
				</div>
			</div>
		</div>
		<!-- bottom栏 -->
		<Mybottom class="My_bottom"></Mybottom>
		<BackTop />
	</div>
	<div class="Mobile_terminal">
		<!--导航栏-->
		<ydnav></ydnav>
		<!--背景视频-->
		<nut-video :options="state.options" :source="state.source"></nut-video>
		<!--新闻部分-->
		<div class="syxw">
			<div class="xwtit">
				<h1>新闻要览</h1>
				<span @click="router.push('/message')">查看更多</span>
			</div>
			<div v-for="(item, index) of state.News_highlights" :key="index" class="single" @click="details(item.id)">
				<div class="single_left">
					<span class="span_t">{{ item.newsDate != null ? item.newsDate.slice(5, 10).replace(/-/g, "/") : "" }}</span>
					<span class="span_o">{{ item.newsDate != null ? item.newsDate.slice(0, 4) : "" }}</span>
				</div>
				<img alt="" src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/PartingLine.png" />
				<div class="single_right">
					<h1>{{ item.newsTitle }}</h1>
					<p>{{ item.remark }}</p>
				</div>
			</div>
		</div>
		<!--数智自贸-->
		<div class="syszzm">
			<div class="szzmtp">
				<div v-for="(item, index) of state.imglist" :key="index" class="szzmdg" @click="imgindex(index, item)">
					<img :src="item.src" />
					<span>{{ item.name }}</span>
				</div>
			</div>
		</div>
		<!--俩图片-->
		<div class="xmltp">
			<div class="Free_trade_on_the_cloudT" @click="router.push('/ydszzm')">
				<div class="left">
					<img alt="" src="../assets/images/AboveT.png" />
					<p>云上自贸</p>
				</div>
			</div>
			<div class="Data_intelligence_and_financial_media">
				<div class="right">
					<img alt="" src="../assets/images/NumberT.png" />
					<p>数智融媒</p>
				</div>
			</div>
		</div>
		<div class="bottomt">
			<div class="bottom_bxt">
				<div class="bottom_leftt">
					<div class="connection_Tt"><img alt="" src="../assets/images/telephone.png" /><span>0532 86768848</span></div>
					<div class="connection_Tt"><img alt="" src="../assets/images/mailbox.png" /><span>266555</span></div>
					<div class="connection_Tt"><img alt="" src="../assets/images/orientation.png" /><span>山东省青岛市黄岛区鹏湾路57号</span></div>
				</div>
			</div>
			<div>
				<p class="bnt">备案：鲁ICF备13025817号</p>
				<p class="bnt">鲁公网安备：37021002000075</p>
			</div>
		</div>
	</div>
</template>
<script setup>
import BackTop from "../components/BackTop.vue";
import ydnav from "@/components/ydnav.vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import api from "../api/api";
import Nav from "@/components/Nav.vue";
import Mybottom from "@/components/Mybottom.vue";
import { onMounted, reactive, ref, nextTick } from "vue";
import { useRouter } from "vue-router"; //引入vue路由
const router = useRouter(); //路由
const state = reactive({
	source: {
		src: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/WeChat_20230912135025.mp4",
		type: "video/mp4",
	},
	options: {
		controls: false,
		autoplay: true,
		muted: true,
		disabled: true,
		playsinline: true,
		loop: true,
	},
	News_highlights_o: [],
	News_highlights: [],
	Device_menu: [],
	Device_menu_middle: [],
	Device_menu_right: [],
	Device_menu_left_index: 0,
	Device_menu_middle_index: 0,
	id: "",
	Device_menu_middle_right_index: 0,
	Device_menu_right_T: [],
	pingeSize: 6,
	pingeIndex: 1,
	total: null,
	totalt: null,
	device: false,
	isRresh: true, //局部刷新值
	imglist: [
		{
			src: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rcgl.png",
			name: "产业赋能",
		},
		{
			src: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/jzzl.png",
			name: "精准治理",
		},
		{
			src: "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/cyfn.png",
			name: "人才管理",
		},
	],
});
const drawer2 = ref(false);
function cancelClick() {
	drawer2.value = false;
}
const ledtxq = () => {
	let { href } = router.resolve(`/details?id=${state.News_highlights_o.id}`);
	window.open(href, "_blank");
	// router.push({ path: "/details", query: { id: state.News_highlights_o.id } });
};
const Device_menu_right = (index, item) => {
	state.Device_menu_middle_right_index = index;
	state.Device_menu_right_T = item;
};
// 局部刷新函数
const update = () => {
	state.isRresh = false;
	nextTick(() => {
		state.isRresh = true;
	});
};
const lfetBtn = () => {
	if (state.pingeIndex == 1) {
		ElMessage({
			message: "已经是首页",
			type: "warning",
		});
	} else {
		state.Device_menu_middle_right_index = 0;
		state.pingeIndex--;
		axios({
			method: "get",
			url: api.productInfo + "?menuId=" + state.Device_menu_middle[state.Device_menu_middle_index].id,
			params: {
				pageIndex: state.pingeIndex,
				pageSize: state.pingeSize,
			},
		}).then(({ data }) => {
			state.Device_menu_right = data.list;
			Device_menu_right(state.Device_menu_middle_right_index, state.Device_menu_right[state.Device_menu_middle_right_index]);
			update(); //调用局部刷新函数
		});
	}
};
const rightBtn = () => {
	if (state.pingeIndex == Math.ceil(state.total / 6)) {
		ElMessage({
			message: "已经是末页",
			type: "warning",
		});
	} else {
		state.Device_menu_middle_right_index = 0;
		state.pingeIndex++;
		axios({
			method: "get",
			url: api.productInfo + "?menuId=" + state.Device_menu_middle[state.Device_menu_middle_index].id,
			params: {
				pageIndex: state.pingeIndex,
				pageSize: state.pingeSize,
			},
		}).then(({ data }) => {
			state.Device_menu_right = data.list;
			Device_menu_right(state.Device_menu_middle_right_index, state.Device_menu_right[state.Device_menu_middle_right_index]);
			update(); //调用局部刷新函数
		});
	}
};
const details = (id) => {
	let { href } = router.resolve(`/details?id=${id}`);
	window.open(href, "_blank");
	// router.push({path: "/details", query: {id: id}});
};
const See_more = () => {
	id: state.id;
	let a = null;
	if (state.pingeIndex == 2) {
		if (state.Device_menu_middle_right_index == 0) {
			a = 5 + 1;
		} else {
			a = 6 + state.Device_menu_middle_right_index;
		}
	} else {
		a = state.Device_menu_middle_right_index;
	}
	router.push({
		path: "/FreeTrade",
		query: {
			yjsy: state.Device_menu_left_index,
			ejsy: state.Device_menu_middle_index,
			id: state.Device_menu_middle[state.Device_menu_middle_index].id,
			index: a,
		},
	});
};
const Device_menu_index = (index, item) => {
	state.Device_menu_middle_index = 0;
	state.Device_menu_left_index = index;
	state.Device_menu_middle_right_index = 0;
	axios({
		method: "get",
		url: api.menu,
	}).then(({ data }) => {
		state.Device_menu_middle = data.list[index].children;
		axios({
			method: "get",
			url: api.productInfo + "?menuId=" + item.children[state.Device_menu_middle_index].id,
			params: {
				pageIndex: state.pingeIndex,
				pageSize: state.pingeSize,
			},
		}).then(({ data }) => {
			state.Device_menu_right = data.list;
			state.total = data.total;
			state.Device_menu_right_T = state.Device_menu_right[state.Device_menu_middle_right_index];
			state.totalt = data.total;
		});
	});
};
const Device_menu_middle_index = (index, id) => {
	state.id = id;
	state.Device_menu_middle_index = index;
	state.pingeIndex = 1;
	axios({
		method: "get",
		url: api.productInfo + "?menuId=" + id,
		params: {
			pageIndex: state.pingeIndex,
			pageSize: state.pingeSize,
		},
	}).then(({ data }) => {
		state.total = data.total;
		state.Device_menu_right = data.list;
		Device_menu_right(0, state.Device_menu_right[0]);
		state.totalt = data.total;
	});
};
const Axios_list = async () => {
	await axios({
		method: "get",
		url: api.NewsList,
		params: {
			pageIndex: 1,
			pageSize: 3,
		},
	}).then(({ data }) => {
		state.News_highlights = data.list;
		state.News_highlights_o = data.list[0];
	});
	await axios({
		method: "get",
		url: api.menu,
	}).then(({ data }) => {
		state.Device_menu = data.list;
	});
	await axios({
		method: "get",
		url: api.menu,
	}).then(({ data }) => {
		state.Device_menu_middle = data.list[state.Device_menu_left_index].children;
		Device_menu_middle_index(state.Device_menu_middle_index, state.Device_menu_middle[0].id);
	});
};
const imgindex = (index, item) => {
	router.push({ path: "/FreeTrade", query: { typeindex: index } });
};
onMounted(() => {
	Axios_list();
	scrollTo(0, 0);
});
</script>
<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}
.nav_com {
	position: absolute;
}
.nut-video {
	height: 100vh;
}
.News_highlights {
	padding-top: 78px;
	height: 652px;
	display: flex;
	justify-content: center;

	.News_highlights_Type_center {
		width: 1398px;

		.News_highlights_h1 {
			display: flex;
			justify-content: space-between;
			align-items: end;
			font-size: 28px;
			font-weight: bold;
			color: #2577ff;
			padding-bottom: 19px;
			border-bottom: 1px solid #ababab;
			margin-bottom: 45px;

			span {
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				cursor: pointer;
			}
		}

		.News_highlights_content {
			display: flex;
			justify-content: space-between;

			.News_highlights_Type_center_left {
				width: 548px;

				img {
					width: 548px;
					height: 305px;
					margin-bottom: 24px;
					cursor: pointer;
				}

				h1 {
					font-size: 28px;
					font-weight: bold;
					//color: #272727;
					line-height: 43px;
					margin-bottom: 24px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					cursor: pointer;

					&:hover {
						color: #2577ff;
					}
				}

				span {
					font-size: 16px;
					font-weight: 400;
					color: #9c9c9c;
					cursor: pointer;
				}
			}

			.News_highlights_Type_center_right {
				.single {
					cursor: pointer;
					display: flex;
					margin-bottom: 76px;

					.single_left {
						display: flex;
						flex-direction: column;
						width: 66.5px;

						.span_o {
							font-size: 16px;
							font-weight: 400;
							color: #969696;
						}

						.span_t {
							font-size: 24px;
							font-weight: 400;
							color: #969696;
							margin-bottom: 16px;
						}
					}

					img {
						margin: 0 22px 0 19px;
					}

					.single_right {
						h1 {
							width: 560px;
							font-size: 20px;
							font-weight: bold;
							color: #616161;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1;
							overflow: hidden;
							margin-bottom: 16px;
						}

						p {
							font-size: 16px;
							font-weight: 400;
							color: #797979;
							line-height: 27px;
							width: 560px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}
					}

					&:hover .single_left span {
						color: #2577ff;
					}

					&:hover .single_right h1 {
						color: #2577ff;
					}

					&:hover .single_right p {
						color: #2577ff;
					}
				}

				.single:nth-last-child(1) {
					margin-bottom: 0;
				}
			}
		}
	}
}
.Device_menu {
	height: 728px;
	background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/HomeBackgroundPicture.png");
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	justify-content: center;

	.Device_menu_Type_center {
		width: 1398px;
		display: flex;
		align-items: flex-start;

		.Device_menu_Type_center_left_content {
			margin-top: 114px;

			.left_single_Device_menu {
				width: 183px;
				height: 60px;
				font-size: 22px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 400;
				color: #ffffff;
				cursor: pointer;
			}

			.active {
				background-color: #2577ff;
			}
		}

		.Device_menu_Type_center_middle_content {
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/bjt.png");
			width: 186px;
			height: 687px;
			margin: 26px 8px 15px 8px;

			.middle_single_Device_menu {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 60px;
				color: #ffffff;
				cursor: pointer;
			}

			.Device_menu_Type_center_middle_content_bx {
				height: 451px;
				overflow: hidden;
				margin-top: 88px;

				.activeTT {
					background-color: #2577ff;
				}
				.activeT {
					background-color: #2577ff;
					cursor: pointer;
				}
			}

			.btn {
				margin-top: 53px;
				display: flex;
				justify-content: center;

				.left {
					width: 42px;
					height: 42px;
					margin-right: 6.5px;
					background-image: url("../assets/images/top.png");
					background-size: 42px;

					&:hover {
						background-image: url("../assets/images/hover.png");
						transform: rotate(180deg);
					}
				}

				.right {
					width: 42px;
					height: 42px;
					margin-left: 6.5px;
					background-image: url("../assets/images/top.png");
					transform: rotate(180deg);

					&:hover {
						background-image: url("../assets/images/hover.png");
						transform: rotate(0deg);
					}
				}
			}
		}

		.Device_menu_Type_center_middle_contentt {
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/bjt.png");
			width: 300px;
			height: 687px;
			margin: 25px 0 15px 0;

			.middle_single_Device_menu {
				width: 176px;
				padding: 22px 68px 22px 56px;
				color: #ffffff;
				font-size: 16px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				cursor: pointer;
			}

			.Device_menu_Type_center_middle_content_bx {
				height: 451px;
				overflow: hidden;
				margin-top: 88px;

				.activeTT {
					background-color: #2577ff;
					cursor: pointer;
				}
				.activeT {
					background-color: #2577ff;
				}
			}

			.btn {
				margin-top: 53px;
				display: flex;
				justify-content: flex-start;
				margin-left: 55px;

				.left {
					width: 42px;
					height: 42px;
					margin-right: 6.5px;
					background-image: url("../assets/images/top.png");
					background-size: 42px;
					cursor: pointer;

					&:hover {
						background-image: url("../assets/images/hover.png");
						transform: rotate(180deg);
					}
				}

				.right {
					width: 42px;
					height: 42px;
					margin-left: 6.5px;
					background-image: url("../assets/images/top.png");
					transform: rotate(180deg);
					cursor: pointer;

					&:hover {
						background-image: url("../assets/images/hover.png");
						transform: rotate(0deg);
					}
				}
			}
		}

		.Device_menu_Type_center_right_content {
			margin-top: 195px;
			margin-left: 149px;
			width: 610px;

			h1 {
				margin-bottom: 45px;
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
			}

			p {
				line-height: 32px;
				font-size: 16px;
				font-weight: 400;
				text-indent: 16px;
				color: #ffffff;
				margin-bottom: 75px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 6;
				overflow: hidden;
			}

			span {
				padding: 20px 70px 20px 70px;
				border: 1px solid #ffffff;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
				cursor: pointer;

				&:hover {
					background-color: #2577ff;
					border: 1px solid #2577ff;
				}
			}
		}
	}
}
.Free_trade_on_the_cloud {
	height: 646px;
	background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/NumberBj.png");
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;

	.Free_trade_on_the_cloudT {
		width: 660px;
		cursor: pointer;
		height: 238px;
		background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/Above.png");
		transition: transform 0.5s ease-in-out;

		&:hover {
			transform: scale(1.1);
			transition: transform 0.5s ease-in-out;
		}

		margin-right: 40.5px;

		.left {
			margin: 61px 0 0 55px;

			img {
				margin-bottom: 22px;
			}

			p {
				font-size: 28px;
				font-weight: bold;
				color: #ffffff;
			}
		}
	}

	.Data_intelligence_and_financial_media {
		width: 660px;
		height: 238px;
		background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/Number.png");
		margin-left: 40.5px;
		transition: transform 0.5s ease-in-out;

		&:hover {
			transform: scale(1.1);
			transition: transform 0.5s ease-in-out;
		}

		.right {
			margin: 61px 0 0 55px;

			img {
				margin-bottom: 22px;
			}

			p {
				font-size: 28px;
				font-weight: bold;
				color: #ffffff;
			}
		}
	}
}

.Mobile_terminal {
	display: none;
}
@media (max-width: 1680px) and(min-width: 1440px) {
	.nav_com,
	.News_highlights_Type_center,
	.Device_menu_Type_center,
	.Free_trade_on_the_cloudT,
	.Data_intelligence_and_financial_media,
	/deep/ .bottom_left,
	/deep/ .right_sce {
		transform: scale(0.8);
	}
	.News_highlights {
		padding-top: 0;
		height: 620px;
		display: flex;
		justify-content: center;
	}
	.Free_trade_on_the_cloud {
		height: 646px;
		background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/NumberBj.png");
		background-repeat: no-repeat;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;

		.Free_trade_on_the_cloudT {
			width: 660px;
			cursor: pointer;
			height: 238px;
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/Above.png");
			transition: transform 0.5s ease-in-out;

			&:hover {
				transform: scale(1.1);
				transition: transform 0.5s ease-in-out;
			}

			margin-right: 0;

			.left {
				margin: 61px 0 0 55px;

				img {
					margin-bottom: 22px;
				}

				p {
					font-size: 28px;
					font-weight: bold;
					color: #ffffff;
				}
			}
		}

		.Data_intelligence_and_financial_media {
			width: 660px;
			height: 238px;
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/Number.png");
			margin-left: 0;
			transition: transform 0.5s ease-in-out;

			&:hover {
				transform: scale(1.1);
				transition: transform 0.5s ease-in-out;
			}

			.right {
				margin: 61px 0 0 55px;

				img {
					margin-bottom: 22px;
				}

				p {
					font-size: 28px;
					font-weight: bold;
					color: #ffffff;
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.Free_trade_on_the_cloud {
		height: 646px;
		background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/NumberBj.png");
		background-repeat: no-repeat;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;

		.Free_trade_on_the_cloudT {
			width: 660px;
			cursor: pointer;
			height: 238px;
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/Above.png");
			transition: transform 0.5s ease-in-out;

			&:hover {
				transform: scale(0.9);
				transition: transform 0.5s ease-in-out;
			}

			margin-right: 0px;

			.left {
				margin: 61px 0 0 55px;

				img {
					margin-bottom: 22px;
				}

				p {
					font-size: 28px;
					font-weight: bold;
					color: #ffffff;
				}
			}
		}

		.Data_intelligence_and_financial_media {
			width: 660px;
			height: 238px;
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/Number.png");
			margin-left: 0px;
			transition: transform 0.5s ease-in-out;

			&:hover {
				transform: scale(0.9);
				transition: transform 0.5s ease-in-out;
			}

			.right {
				margin: 61px 0 0 55px;

				img {
					margin-bottom: 22px;
				}

				p {
					font-size: 28px;
					font-weight: bold;
					color: #ffffff;
				}
			}
		}
	}
	.News_highlights {
		padding-top: 0;
		height: 620px;
		display: flex;
		justify-content: center;
	}
}

@media (max-width: 1439px) and(min-width: 1100px) {
	.nav_com,
	.News_highlights_Type_center,
	.Device_menu_Type_center,
	.Free_trade_on_the_cloudT,
	.Data_intelligence_and_financial_media,
	/deep/ .bottom_left,
	/deep/ .right_sce {
		transform: scale(0.8);
	}
	.News_highlights {
		padding-top: 0;
		height: 620px;
		display: flex;
		justify-content: center;
	}
}

@media screen and (max-width: 769px) {
	.Mobile_terminal {
		display: block;

		.logo {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 8vh;
			//background-color: #2577FF;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.logoDiv {
				margin-left: 30px;

				img {
					width: 25vw;
				}
			}

			span {
				padding-right: 30px;
				width: 25px;
				height: 25px;
			}
			.nav {
				p {
					color: #ffffff;
					margin-bottom: 20px;
					font-size: 18px;
					margin-left: 10px;
				}
			}
		}

		.nut-video {
			height: 100%;
		}

		.syxw {
			padding: 40px 30px 0 30px;
			margin-bottom: 30px;

			.xwtit {
				width: 100%;
				border-bottom: 2px dashed #a1a1a1;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 5px;
				margin-bottom: 30px;

				h1 {
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #2577ff;
				}

				span {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					cursor: pointer;
				}
			}

			.single {
				cursor: pointer;
				width: 100%;
				height: 5.375rem;
				display: flex;
				margin-bottom: 50px;

				.single_left {
					display: flex;
					flex-direction: column;
					width: 66.5px;

					.span_o {
						font-size: 16px;
						font-weight: 400;
						color: #969696;
					}

					.span_t {
						font-size: 18px;
						font-weight: 400;
						color: #969696;
						margin-bottom: 16px;
					}
				}

				img {
					margin: 0 10px 0 10px;
				}

				.single_right {
					h1 {
						width: 100%;
						font-size: 18px;
						font-weight: bold;
						color: #616161;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
						margin-bottom: 12px;
					}

					p {
						font-size: 14px;
						font-weight: 400;
						color: #797979;
						line-height: 27px;
						width: 100%;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
				}

				&:hover .single_left span {
					color: #2577ff;
				}

				&:hover .single_right h1 {
					color: #2577ff;
				}

				&:hover .single_right p {
					color: #2577ff;
				}
			}

			.single:nth-last-child(1) {
				margin-bottom: 0;
			}
		}

		.syszzm {
			height: 200px;
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szzmbjt.png");
			background-repeat: no-repeat;
			background-position: center, center;
			padding: 0px 30px 0 30px;
			//margin-bottom: 30px;
			display: flex;
			align-items: center;

			.szzmtp {
				width: 100%;
				display: flex;
				justify-content: space-between;

				.szzmdg {
					display: flex;
					flex-direction: column;
					align-items: center;

					img {
						width: 50px;
						height: 50px;
						margin-bottom: 10px;
					}

					span {
						color: #ffffff;
					}
				}
			}
		}

		.xmltp {
			width: 100%;

			.Free_trade_on_the_cloudT {
				height: 150px;
				background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/Above.png");
				background-position: center, center;
				background-repeat: no-repeat;
				display: flex;
				align-items: center;

				.left {
					padding: 0px 0 0 55px;

					img {
						width: 25px;
						height: 20px;
						margin-bottom: 10px;
					}

					p {
						font-size: 18px;
						font-weight: bold;
						color: #ffffff;
					}
				}
			}

			.Data_intelligence_and_financial_media {
				height: 150px;
				background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/Number.png");
				background-position: center, center;
				background-repeat: no-repeat;
				display: flex;
				align-items: center;

				.right {
					padding: 0px 0 0 55px;

					img {
						width: 25px;
						height: 20px;
						margin-bottom: 10px;
					}

					p {
						font-size: 18px;
						font-weight: bold;
						color: #ffffff;
					}
				}
			}
		}

		.bottomt {
			padding: 0 30px 0 55px;
			background-color: #333333;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.bottom_bxt {
				.bottom_leftt {
					margin-top: 20px;

					.connection_Tt {
						margin-bottom: 20px;

						img {
							width: 16px;
							margin-right: 12px;
						}

						span {
							font-size: 13px;
							font-weight: bold;
							color: #ffffff;
						}
					}
				}
			}

			.bnt {
				font-size: 13px;
				font-weight: bold;
				color: #ffffff;
				opacity: 0.6;
				display: flex;
				justify-content: flex-start;
				margin-bottom: 20px;
			}
		}
	}

	/deep/ .el-drawer__header {
		padding-right: 30px;
	}

	.Computer_terminal {
		display: none;
	}
}
</style>
