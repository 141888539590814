<template>
	<div class="Computer_terminal">
		<Nav :changeSelectStyle="1" class="nav-home"></Nav>
		<div class="Part_tow">
			<h1>数智成果</h1>
		</div>
		<div class="Part_three">
			<div class="Part_three_bx">
				<div class="Part_three_bx_btn">
					<span v-for="(item, index) of xwnav" :key="index" :class="{ changeStyleT: ind == index }" @click="qieH(index)">{{ item.name }}</span>
				</div>
				<div class="bx">
					<div class="bxt">
						<h1>
							{{ list.newsTitle }}
						</h1>
						<div class="xz">
							<span> {{ list.newsDate }}</span>
						</div>
					</div>
				</div>
				<div class="bx_content">
					<p v-html="list.newsContent"></p>
					<!-- <img :src="list.filePath" alt="诶呦！照片丢啦" /> -->
				</div>
				<div class="bnt" @click="dzxz()">
					<span class="bnt1" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave"><span>下载附录</span> <img :src="xztp" alt="" /></span>
				</div>
			</div>
		</div>
		<BottomPanel></BottomPanel>
		<BackTop />
	</div>
	<div class="Mobile_terminal">
		<!--    导航-->
		<ydnav></ydnav>
		<!--    新闻资讯-->
		<div class="achievement_bj">
			<h1>数智成果</h1>
		</div>
		<div class="Part_three">
			<div class="Part_three_bx">
				<div class="Part_three_bx_btn">
					<span v-for="(item, index) of xwnav" :key="index" :class="{ changeStyleT: ind == index }" @click="qieH(index)">{{ item.name }}</span>
				</div>
				<div class="bx">
					<div class="bxt">
						<h1>
							{{ list.newsTitle }}
						</h1>
						<div class="xz">
							<span> {{ list.newsDate }}</span>
							<span>作者：{{ list.author }}</span>
						</div>
					</div>
				</div>
				<div class="bx_content">
					<p v-html="list.newsContent"></p>
					<!-- <img :src="list.filePath" alt="诶呦！照片丢啦" /> -->
				</div>
				<div class="bnt" @click="dzxz()">
					<span class="bnt1" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave"><span>下载附录</span> <img :src="xztp" alt="" /></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import BackTop from "../components/BackTop.vue";
import ydnav from "@/components/ydnav.vue";
import Nav from "../components/Nav.vue"; //引入导航栏组件
import BottomPanel from "../components/Mybottom.vue"; //引入底部组件
import axios from "axios"; //引入axios
import api from "../api/api"; //引入cimos接口api
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router"; //引入vue路由
const router = useRouter(); //路由
const { currentRoute } = useRouter();
const route = currentRoute.value;
const xwnav = ref([
	{
		name: "数智化制度创新",
	},
]);
const xztp = ref("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png");
const handleMouseEnter = () => {
	xztp.value = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/bxz.png";
};
const handleMouseLeave = () => {
	xztp.value = "https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png";
};
const ind = ref(0);
const qieH = (index) => {
	ind.value = index;
};
const list = ref([]);
const dzxz = () => {
	location.href = api.downloadZip + "?type=2" + "&id=" + list.value.id;
};
const webNews = async () => {
	await axios({
		method: "get",
		url: api.system + "/" + route.query.id,
	}).then(({ data }) => {
		list.value = data.val;
	});
};

onMounted(() => {
	webNews();
	scrollTo(0, 0);
});
</script>
<style lang="less" scoped>
.nav-home {
	position: absolute;
	background-color: #ffffff00;
}

.Part_tow {
	margin-bottom: 70px;
	height: 410px;
	background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/achievement.png");
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	align-items: center;

	h1 {
		margin-left: 18.5%;
		font-size: 42px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}
}

.Part_three {
	.Part_three_bx {
		.Part_three_bx_btn {
			display: flex;
			justify-content: center;
			margin-bottom: 50px;

			span {
				padding: 27px 75px 27px 75px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #ffffff;
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #3d7effff;
			}

			span:nth-child(1) {
				margin-right: 0px;
			}

			span:nth-child(2) {
				margin-left: 0px;
			}

			.changeStyleT {
				background-color: #2577ff;
				color: #ffffff;
			}
		}

		.bx {
			width: 1200px;
			margin: 0 auto;
			padding-bottom: 26px;
			border-bottom: 1px solid #ababab;
			margin-bottom: 52px;
			display: flex;
			justify-content: center;

			.bxt {
				//width: 934px;
				display: flex;
				flex-direction: column;

				h1 {
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 36px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #272727;
					margin-bottom: 33px;

					p {
						width: 42px;
						height: 42px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: 91px;
						background-image: url(../assets/z.png);

						&:hover {
							background-image: url(../assets/y.png);
							transform: rotate(180deg);
						}
					}
				}

				.xz {
					margin: 0 auto;
					span {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #808080;
						margin-right: 25px;
					}
				}
			}

			.xz {
				span {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #808080;
					margin-right: 25px;
				}
			}
		}

		.bx_content {
			width: 803px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;

			p {
				width: 803px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #313131;
				line-height: 32px;
				margin-bottom: 42px;

				/deep/ img {
					width: 803px;
				}
				/deep/ video {
					width: 803px;
				}
			}

			img {
				margin-bottom: 224px;
			}
		}
	}
}

.bnt {
	display: flex;
	justify-content: space-between;
	justify-content: center;
	margin-bottom: 50px;

	.bnt1 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 240px;
		height: 56px;
		border: 1px solid #b8b8b8;

		span {
			margin-left: 28px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			font-size: 14px;
			color: #4c4c4c;
		}

		img {
			margin-right: 24px;
		}

		&:hover {
			background-color: #2577ff;
		}

		&:hover span {
			color: #ffffff;
		}
	}

	.bnt2 {
		display: flex;
		align-items: center;

		span {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #7e7e7e;
			margin-right: 11px;
		}

		img {
			width: 20px;
			height: 17px;
		}
	}
}

.Mobile_terminal {
	display: none;
}

@media (max-width: 1680px) and(min-width: 1440px) {
	.nav-home,
	.Part_three.bnt {
		transform: scale(0.8);
	}
}

@media (max-width: 1439px) and(min-width: 1100px) {
	.nav-home,
	.Part_three.bnt {
		transform: scale(0.8);
	}
}

@media screen and (max-width: 769px) {
	.Mobile_terminal {
		display: block;

		.achievement_bj {
			height: 200px;
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/part2.png");
			background-repeat: no-repeat;
			background-position: center center;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			h1 {
				margin-left: 18.5%;
				font-size: 20px;
				font-weight: bold;
				color: #ffffff;
			}
		}

		.Part_three {
			padding: 0 30px 0 30px;

			.Part_three_bx {
				.Part_three_bx_btn {
					display: flex;
					justify-content: center;
					margin-bottom: 90px;

					span {
						padding: 27px 75px 27px 75px;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #ffffff;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #3d7effff;
					}

					span:nth-child(1) {
						margin-right: 0px;
					}

					span:nth-child(2) {
						margin-left: 57.5px;
					}

					.changeStyleT {
						background-color: #2577ff;
						color: #ffffff;
					}
				}

				.bx {
					width: 100%;
					margin: 0 auto;
					padding-bottom: 26px;
					border-bottom: 2px dashed #a7a7a7;
					margin-bottom: 52px;
					display: flex;
					justify-content: center;

					.bxt {
						width: 100%;
						display: flex;
						flex-direction: column;

						h1 {
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 20px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #272727;
							margin-bottom: 33px;

							p {
								width: 100%;
								height: 42px;
								display: flex;
								justify-content: center;
								align-items: center;
								margin-left: 91px;
								background-image: url(../assets/z.png);

								&:hover {
									background-image: url(../assets/y.png);
									transform: rotate(180deg);
								}
							}
						}

						.xz {
							span {
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #808080;
								margin-right: 25px;
							}
						}
					}

					.xz {
						span {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #808080;
							margin-right: 25px;
						}
					}
				}

				.bx_content {
					width: 100%;
					margin: 0 auto;
					display: flex;
					flex-direction: column;

					p {
						width: 100%;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #313131;
						line-height: 32px;
						margin-bottom: 42px;

						/deep/ img {
							width: 100%;
						}
					}

					img {
						margin-bottom: 224px;
					}
				}
			}
		}
	}

	.Computer_terminal {
		display: none;
	}
}
</style>
