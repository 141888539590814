<template>
	<div class="Computer_terminal">
		<!--    导航栏-->
		<Nav :changeSelectStyle="3" class="nav-home"></Nav>
		<!--    数智自贸-->
		<div class="achievement_bj"><h1>数智自贸</h1></div>
		<!--    内容-->
		<div class="bx">
			<div class="Part_three">
				<div class="bt">
					<div class="tit">
						<h2 v-for="(item, index) of tit" :key="index" :class="{ changeStyleT: changeSelectStyleT == index }" @click="styleClassT(index, item)">{{ item.menuName }}</h2>
					</div>
				</div>
				<div class="Part_three_content">
					<div class="Part_three_content_left">
						<div v-for="(item, index) of tit[changeSelectStyleT].children" :key="index" :class="{ changeStyle: lefttitstyle == index }" class="left_dg" @click="styleClass(index, item.id)">{{ item.menuName }} <img class="icon" src="https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png" /></div>
					</div>
					<div class="demo-collapse" style="margin-left: 20px; width: 74%">
						<div class="dgxx" v-for="(item, index) of listArray" @click="dy(index, item)">
							{{ item.title }}
							<div class="back"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<BottomPanel></BottomPanel>
		<BackTop />
	</div>
	<div class="Mobile_terminal">
		<ydnav></ydnav>
		<!--数智自贸-->
		<div class="achievement_bj">
			<h1>数智自贸</h1>
		</div>
		<div class="yddbx">
			<van-tabs v-model:active="listyd.activeindex" @click-tab="onClickTab">
				<van-tab v-for="(item, index) of tit" :key="index" :name="item.index" :title="item.menuName">
					<div class="ydd">
						<van-sidebar v-model="active">
							<van-sidebar-item v-for="(item, index) of tit[ind].children" :key="index" :title="item.menuName" @click="djtj(index, item.id)" />
						</van-sidebar>
						<!-- <van-collapse v-model="activeName" accordion>
							<van-collapse-item v-for="(item, index) of listyd.yddcd" :key="index" :name="index" :title="item.title">
								<p v-html="item.content"></p>
							</van-collapse-item>
						</van-collapse> -->
						<div class="demo-collapse" style="margin-left: 20px; width: 68%">
							<div class="dgxx" v-for="(item, index) of listyd.yddcd" @click="dy(index, item)">
								{{ item.title }}
								<div class="back"></div>
							</div>
						</div>
					</div>
				</van-tab>
			</van-tabs>
		</div>
		<div class="bottomt">
			<div class="bottom_bxt">
				<div class="bottom_leftt">
					<div class="connection_Tt"><img alt="" src="../assets/images/telephone.png" /><span>0532 86768848</span></div>
					<div class="connection_Tt"><img alt="" src="../assets/images/mailbox.png" /><span>266555</span></div>
					<div class="connection_Tt"><img alt="" src="../assets/images/orientation.png" /><span>山东省青岛市黄岛区鹏湾路57号</span></div>
				</div>
			</div>
			<div>
				<p class="bnt">备案：鲁ICF备13025817号</p>
				<p class="bnt">鲁公网安备：37021002000075</p>
			</div>
		</div>
	</div>
</template>
<script setup>
import BackTop from "../components/BackTop.vue";
import ydnav from "@/components/ydnav.vue";
import Nav from "../components/Nav.vue"; //引入导航栏组件
import BottomPanel from "../components/Mybottom.vue"; //引入底部组件
import axios from "axios"; //引入axios
import api from "../api/api"; //引入cimos接口api
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router"; //引入vue路由
const router = useRouter(); //路由
const { currentRoute } = useRouter();
const route = currentRoute.value;

const activeNames = ref(null);
const active = ref(0);
const activeName = ref("1");
const tit = ref([
	{
		name: "精准治理",
	},
	{
		name: "产业赋能",
	},
	{
		name: "企业服务",
	},
]);
const listArray = ref([]);
const changeSelectStyleT = ref(0);
const ind = ref(0);
const listyd = reactive({
	yddcd: [],
	activeindex: 0,
});
const dy = (index, item) => {
	let { href } = router.resolve(`/szzmxq?id=${item.id}`);
	window.open(href, "_blank");
	// router.push({ path: "/szzmxq", query: { id: item.id } });
};
const djtj = (index, id) => {
	axios({
		method: "get",
		url: api.productInfo + "?menuId=" + id,
	}).then(({ data }) => {
		listyd.yddcd = data.list;
	});
};
const onClickTab = (data) => {
	ind.value = data.name;
	let a = tit.value[ind.value].children[0];
	axios({
		method: "get",
		url: api.productInfo + "?menuId=" + a.id,
	}).then(({ data }) => {
		listyd.yddcd = data.list;
	});
};
const cpxx = (id) => {
	location.href = api.downloadZip + "?type=4" + "&id=" + id;
};
const styleClassT = (index, item) => {
	lefttitstyle.value = 0;
	changeSelectStyleT.value = index;
	if (item.children == null) {
		listArray.value = [];
	} else {
		axios({
			method: "get",
			url: api.productInfo + "?menuId=" + item.children[0].id,
		}).then(({ data }) => {
			listArray.value = data.list;
		});
	}
};
const lefttitstyle = ref(0);
const styleClass = (index, id) => {
	activeNames.value = null;
	lefttitstyle.value = index;
	axios({
		method: "get",
		url: api.productInfo + "?menuId=" + id,
	}).then(({ data }) => {
		listArray.value = data.list;
	});
};
const dycd = async () => {
	await axios({
		method: "get",
		url: api.menu,
	}).then(({ data }) => {
		tit.value = data.list;
		if (route.query.yjsy && route.query.ejsy) {
			styleClass(route.query.ejsy, route.query.id);
			lefttitstyle.value = route.query.ejsy;
			changeSelectStyleT.value = route.query.yjsy;
			axios({
				method: "get",
				url: api.productInfo + "?menuId=" + route.query.id,
			}).then(({ data }) => {
				listArray.value = data.list;
				activeNames.value = Number(route.query.index);
			});
		} else {
			styleClass(0, data.list[0].children[0].id);
		}
		if (route.query.typeindex) {
			djtj(route.query.typeindex, data.list[route.query.typeindex].children[0].id);
			ind.value = route.query.typeindex;
		} else {
			djtj(0, data.list[0].children[0].id);
		}
	});
};
onMounted(() => {
	dycd();
	scrollTo(0, 0);
	listyd.activeindex = Number(route.query.typeindex);
});
</script>
<style lang="less" scoped>
.nav-home {
	position: absolute;
	background-color: #ffffff00;
}

.achievement_bj {
	height: 410px;
	background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szzm.png");
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	align-items: center;

	h1 {
		margin-left: 18.5%;
		font-size: 42px;
		font-weight: bold;
		color: #ffffff;
		font-family: Microsoft YaHei;
	}
}

/deep/ .el-collapse-item__wrap {
	display: none;
}

.bx {
	width: 1200px;
	margin: 0 auto;

	.Part_three {
		.bt {
			padding-top: 86px;
			width: 100%;
			padding-bottom: 0px;
			border-bottom: 1px solid #ababab;
			margin-bottom: 97px;

			.tit {
				display: flex;
				justify-content: center;

				h2 {
					cursor: pointer;
					padding: 17px 44px;
					font-size: 28px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #313333;
				}

				.changeStyleT {
					background-color: #2577ff;
					color: #ffffff;
				}
			}
		}

		.Part_three_content {
			display: flex;
			margin-bottom: 224px;

			.Part_three_content_left {
				float: left;

				.left_dg {
					cursor: pointer;
					width: 289px;
					height: 84px;
					font-size: 18px;
					display: flex;
					align-items: center;
					padding-left: 32px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #434343;
					background-color: #ececec;
					margin-bottom: 6px;
					justify-content: space-between;

					.icon {
						display: none;
					}
				}

				.changeStyle {
					background-color: #2577ff;
					color: #ffffff;

					.icon {
						width: 20px;
						height: 20px;
						display: block;
						margin-right: 30px;
					}
				}
			}

			.Part_three_content_right {
				width: 775px;

				img {
					margin-bottom: 57px;
				}

				h1 {
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #272727;
					margin-bottom: 28px;
				}

				.po {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #2577ff;
					margin-bottom: 41px;
				}

				.pt {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #272727;
					line-height: 32px;
					margin-bottom: 55px;
				}

				h2 {
					font-size: 21px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #3d7eff;
					margin-bottom: 32px;
				}

				.zyzfnr {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 28px;

					.zfnr:nth-child(1) {
						margin-left: 0px;
					}

					.zfnr:nth-child(4) {
						margin-left: 0px;
					}

					.zfnr {
						padding: 10px 17px 11px 18px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #272727;
						background-color: #f3f3f3;
						box-sizing: border-box;
						border-left: 3px solid #3d7eff;
						margin-left: 18px;
						margin-bottom: 28px;
					}
				}

				h3 {
					font-size: 21px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #3d7eff;
					margin-bottom: 27px;
				}

				ol,
				li {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #272727;
					line-height: 32px;
					margin-left: 10px;
				}
			}
		}
	}
}

/deep/ .el-collapse-item__header {
	font-size: 18px;
	//font-weight: bolder;
	//background-color: #2577ff;
	//color: #FFFFFF;
	padding-left: 10px;
}

/deep/ .el-collapse-item__content {
	p {
		/deep/ img {
			width: 803px;
		}
	}
}

.bnt {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	justify-content: center;
	margin-bottom: 50px;

	.bnt1 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 240px;
		height: 56px;
		border: 1px solid #b8b8b8;
		margin-top: 50px;

		span {
			margin-left: 28px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			font-size: 14px;
			color: #4c4c4c;
		}

		.img {
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/xz.png");
			width: 20px;
			height: 17px;
			margin-right: 24px;
		}

		&:hover {
			border: 1px solid #2577ff;
			background-color: #2577ff;
		}

		&:hover .img {
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/bxz.png");
		}

		&:hover span {
			color: #ffffff;
		}
	}

	.bnt2 {
		display: flex;
		align-items: center;

		span {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #7e7e7e;
			margin-right: 11px;
		}

		img {
			width: 20px;
			height: 17px;
		}
	}
}

/deep/ .el-collapse-item__content p img {
	width: 100%;
}

/deep/ .el-collapse-item__content video {
	width: 100%;
}
.demo-collapse {
	.dgxx:nth-child(1) {
		border-top: 1px solid #f3f4f8;
	}
	.dgxx {
		cursor: pointer;
		width: 100%;
		height: 48px;
		display: flex;
		align-items: center;
		// background-color: #f1f1f1;
		background-color: #ffffff;
		// margin-bottom: 10px;
		padding-left: 10px;
		padding-right: 10px;
		justify-content: space-between;
		border-bottom: 1px solid #f3f4f8;
		.back {
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/right.png");
			background-size: 15px;
			background-repeat: no-repeat;
			width: 15px;
			height: 15px;
		}
		&:hover {
			background-color: #2577ff;
			color: #ffffff;
		}
		&:hover .back {
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/rigthT.png");
			background-size: 15px;
			background-repeat: no-repeat;
			width: 15px;
			height: 15px;
		}
	}
}
.Mobile_terminal {
	display: none;
}

@media (max-width: 1680px) and(min-width: 1440px) {
	.nav-home,
	/deep/ .bottom_left,
	/deep/ .right_sce,
	.bx {
		transform: scale(0.8);
	}
	.bx .Part_three .bt {
		padding-top: 0;
		width: 100%;
		padding-bottom: 1px;
		border-bottom: 1px solid #ababab;
		margin-bottom: 97px;
	}
}

@media (max-width: 1439px) and(min-width: 1100px) {
	.nav-home,
	/deep/ .bottom_left,
	/deep/ .right_sce,
	.bx {
		transform: scale(0.8);
	}
	.bx .Part_three .bt {
		padding-top: 0;
		width: 100%;
		padding-bottom: 1px;
		border-bottom: 1px solid #ababab;
		margin-bottom: 97px;
	}
}

@media screen and (max-width: 769px) {
	.Mobile_terminal {
		display: block;

		.achievement_bj {
			height: 200px;
			background-image: url("https://source.cimos.cn/projects/web/zmq/Number_of_wisdom_free_trade/images/szzm.png");
			background-repeat: no-repeat;
			background-position: center center;
			display: flex;
			align-items: center;

			h1 {
				margin-left: 18.5%;
				font-size: 20px;
				font-weight: bold;
				color: #ffffff;
			}
		}

		.yddbx {
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 30px;
			margin-bottom: 30px;

			.ydd {
				display: flex;

				/deep/ .van-collapse-item {
					width: 250px;
				}

				p {
					/deep/ img {
						width: 100%;
					}

					/deep/ video {
						width: 100%;
					}
				}
			}
		}

		.bottomt {
			padding: 0 30px 0 55px;
			background-color: #333333;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.bottom_bxt {
				.bottom_leftt {
					margin-top: 20px;

					.connection_Tt {
						margin-bottom: 20px;

						img {
							width: 16px;
							margin-right: 12px;
						}

						span {
							font-size: 13px;
							font-weight: bold;
							color: #ffffff;
						}
					}
				}
			}

			.bnt {
				font-size: 13px;
				font-weight: bold;
				color: #ffffff;
				opacity: 0.6;
				display: flex;
				justify-content: flex-start;
				margin-bottom: 20px;
			}
		}
	}

	.Computer_terminal {
		display: none;
	}
}
</style>
